import { useCallback, useEffect, useState, MouseEvent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell,
    TableBody, Paper, Container, Button, Stack, Modal, InputLabel, TextField, Divider, Select, FormControl, MenuItem, SelectChangeEvent, Popover } from "@mui/material"
import { useDispatch, useSelector } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../../types";
import moment from "moment";
import { useAuth } from "../../../services/authContext";
import { socket } from "../../../services/socket";
import {
    createCampaign, getCampaignOne,
    getCampaigns,
    pauseCampaign,
    resendCampaign, sendCampaignSingle, unrepliedCampaign,
    updateCampaign
} from "../../../redux/campaign/campaign.request";
import {archiveCampaignFilter, clearCampaigns, onCampaignUpdate} from "../../../redux/campaign/campaign.slice";

export const CampaignsList = () => {
    const { me } = useAuth()
    const [openModal, setOpenModal] = useState(false);
    const [campaignType, setCampaignType] = useState('');
    const [campaignName, setCampaignName] = useState('')
    const [showArchived, setShowArchived] = useState(false)
    const [updatedCampaignIds, setUpdatedCampaignIds] = useState<string[]>([]);
    const [loadCampaignIds, setLoadCampaignIds] = useState<string[]>([]);
    const [horizontalScroll, setHorizontalScroll] = useState(0)

    const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedCampaignId, setSelectedCampaignId] = useState<null | string>(null);
    const isPopoverOpen = Boolean(popoverAnchorEl);

    const { campaigns, isLoading, isLoadingSendCampaign } = useSelector(store => store.campaign)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getCampaigns({ archived: showArchived }))

        return () => { dispatch(clearCampaigns()) }
    }, [showArchived, dispatch])

    useEffect(() => {
        if (updatedCampaignIds.length > 0) {
            const timer = setTimeout(() => {
                setUpdatedCampaignIds([]);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [updatedCampaignIds]);

    useEffect(() => {
        socket.on('upd_campaign', (data): any => {
            dispatch(onCampaignUpdate(data));
        })

        return () => { socket.off('upd_campaign')}
    }, [dispatch])

    useEffect(() => {

        socket.on('load_campaign', (data): any => {
            if (!loadCampaignIds.includes(data.campaign_id) && data.status === 'start') {
                setLoadCampaignIds(prevIds => [...prevIds, data.campaign_id]);
            }
            else if(!loadCampaignIds.includes(data.campaign_id)) {
                setLoadCampaignIds(prevIds => prevIds.filter(id => id !== data.campaign_id))
            }
        })

        return () => { socket.off('load_campaign')}

    }, [dispatch]);

    const addUpdatedCampaignId = (id: string) => {
        setUpdatedCampaignIds(prevIds => [...prevIds, id]);
    };

    useEffect(() => {
        const handleCampaignGet = (data: any) => {
            if (data.campaign_id) {
                const campaignExists = campaigns.some(campaign => campaign.id === data.campaign_id);
                if (campaignExists) {
                    dispatch(getCampaignOne(data.campaign_id));
                    addUpdatedCampaignId(data.campaign_id);
                }
            }
        };

        socket.on('hook_upd_campaign', handleCampaignGet);

        return () => {
            socket.off('hook_upd_campaign');
        };
    }, [dispatch, campaigns]);

    const onCampaignLoader = (data: any) => {

    }

    const handleChange = (event: SelectChangeEvent) => {
        setCampaignType(event.target.value as string);
    };

    const onCreateCampaign = useCallback(() => {
        if(!campaignName || !campaignType) { console.log('err'); return 'err'}
        dispatch(createCampaign({ name: campaignName, type: campaignType }))
        setOpenModal(false)
    }, [campaignName, campaignType, dispatch])

    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 0.5;

        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }

        if (isAtBottom && !isLoading) {
            dispatch(getCampaigns({ archived: showArchived }))
        }
    }, [horizontalScroll, isLoading, dispatch, showArchived])

    const archiveCampaign = (id: string) => {
        dispatch(archiveCampaignFilter(id))
        dispatch(updateCampaign({ id, payload: { is_archived: true } }))
    }
    const unArchiveCampaign = (id: string) => {
        dispatch(updateCampaign({ id, payload: { is_archived: false } }))
    }

    const handleResendCampaign = async (id: string) => {
        dispatch(resendCampaign({ id }))
        handlePopoverClose()
    }

    const handleUnrepliedCampaign = async (id: string) => {
        dispatch(unrepliedCampaign({ id }))
        handlePopoverClose()
    }

    const handlePause = (id: string) => {
        dispatch(pauseCampaign({ id }))
    }
    const handleSend = (id: string) => {
        dispatch(sendCampaignSingle({ id, payload: { message: '' } }))
    }

    const handlePopoverOpen = (event: MouseEvent<HTMLButtonElement>, campaignId: string) => {
        setPopoverAnchorEl(event.currentTarget);
        setSelectedCampaignId(campaignId);
    };

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
        setSelectedCampaignId(null);
    };

    return (
        <div>
            <Typography variant="h5">Campaigns</Typography>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                <Box>
                    <Typography variant="h6">Campaigns</Typography>
                    <Typography sx={{ fontSize: 12 }} variant="subtitle2">Here's a list of all your workspace's campaigns.</Typography>
                </Box>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    {me?.role !== 'admin' && <Button size="small" variant="contained" onClick={() => setOpenModal(true)}>Create Campaign</Button>}
                    <Button onClick={() => setShowArchived(prev => !prev)} size="small" color="inherit" variant="contained">Show Archived {showArchived ? 'on' : 'off'}</Button>
                    <Button size="small" variant="contained">Download</Button>
                </Stack>
            </Container>

            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: '600px' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>CAMPAIGN</TableCell>
                            <TableCell>TYPE</TableCell>
                            <TableCell>CREATED BY</TableCell>
                            <TableCell>CREATED AT</TableCell>
                            <TableCell>SENT</TableCell>
                            {/* <TableCell>DELIVERED</TableCell> */}
                            <TableCell>RESPONSED</TableCell>
                            <TableCell>STOP</TableCell>
                            <TableCell>SPAM</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {campaigns.map((campaign) => (

                            <TableRow
                                key={campaign.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell style={{ cursor: 'pointer'}} onClick={() => navigate(campaign.id)} component="th" scope="row">
                                    {campaign?.name}
                                </TableCell>
                                <TableCell>{campaign.type === 'single_blast' ? 'Single Blast' : 'Scheduled'}</TableCell>
                                <TableCell>{campaign.user ? campaign.user.firstname + ' ' + campaign.user.lastname : ''}</TableCell>
                                <TableCell>{moment(campaign.createdAt).format('MM/DD/YYYY hh:mm A')}</TableCell>
                                <TableCell><Box sx={{animation: updatedCampaignIds.includes(campaign.id) ? 'highlight 3s' : 'none'}}>{campaign.total_sended}/{campaign.total}</Box></TableCell>
                                {/* <TableCell>{0}</TableCell> */}
                                <TableCell><Box sx={{animation: updatedCampaignIds.includes(campaign.id) ? 'highlight 3s' : 'none'}}>{campaign.total_answered}</Box></TableCell>
                                <TableCell><Box sx={{animation: updatedCampaignIds.includes(campaign.id) ? 'highlight 3s' : 'none'}}>{campaign.stop}</Box></TableCell>
                                <TableCell><Box sx={{animation: updatedCampaignIds.includes(campaign.id) ? 'highlight 3s' : 'none'}}>{campaign.spam}</Box></TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        {campaign.status === 'created' && campaign.total_sended > 0 && <Typography style={{ whiteSpace: 'nowrap' }}>{campaign.total_sended} of {campaign.total} sent
                                            {loadCampaignIds.includes(campaign.id) ? (
                                                <FontAwesomeIcon style={{marginLeft: '4px'}} icon={"fa-solid fa-play".split(' ') as any} />
                                            ) : (
                                                <FontAwesomeIcon style={{ cursor: 'pointer', marginLeft: 4 }} onClick={() => handleSend(campaign.id)} icon={"fa-solid fa-repeat".split(' ') as any}></FontAwesomeIcon>
                                            )}
                                        </Typography>}
                                        {campaign.status === 'pending' && <Typography style={{ whiteSpace: 'nowrap' }}>Sending<FontAwesomeIcon style={{ cursor: 'pointer', marginLeft: 4 }} onClick={() => handlePause(campaign.id)} icon={"fa-solid fa-pause".split(' ') as any}></FontAwesomeIcon></Typography>}
                                        {/* {campaign.status === 'pending' && <Typography style={{ whiteSpace: 'nowrap' }}>Sending {campaign.total_sended} of {campaign.total} <FontAwesomeIcon style={{ cursor: 'pointer', marginLeft: 4 }} onClick={() => handlePause(campaign.id)} icon={"fa-solid fa-pause".split(' ') as any}></FontAwesomeIcon></Typography>} */}
                                        {campaign.status === 'sended' && <Typography>Completed</Typography>}
                                        {campaign.status === 'created' && campaign.total_sended === 0 && <Typography>Not sent</Typography>}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        {campaign.is_archived
                                            ? me?.role !== 'admin' && <Button variant="contained" color="inherit" onClick={() => unArchiveCampaign(campaign.id)}>Un archive</Button>
                                            : me?.role !== 'admin' && <Button variant="contained" color="inherit" onClick={() => archiveCampaign(campaign.id)}>Archive</Button>
                                        }
                                        {me?.role !== 'admin' && (
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                onClick={(e) => handlePopoverOpen(e, campaign.id)}
                                            >
                                                Resend
                                            </Button>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Popover
                open={isPopoverOpen}
                anchorEl={popoverAnchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
                    <Button onClick={() => { if(selectedCampaignId) handleResendCampaign(selectedCampaignId); }}>To All</Button>
                    <Button onClick={() => { if(selectedCampaignId) handleUnrepliedCampaign(selectedCampaignId); }}>To Unreplied</Button>
                </Box>
            </Popover>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Create new campaign
                        </Typography>
                        <div style={{ cursor: 'pointer' }} onClick={() => setOpenModal(false)}>
                            {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                        </div>
                    </Box>
                    <Divider></Divider>

                    <InputLabel sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1, marginTop: 2 }}>
                        {/* <Typography>Campaign Name </Typography> */}
                        <TextField value={campaignName} onChange={(e) => setCampaignName(e.target.value)} style={{ width: '100%' }} placeholder="Name your campaign" id="outlined-basic" variant="outlined" />
                    </InputLabel>

                    <FormControl style={{ marginTop: 10, width: '100%' }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH }} id="demo-simple-select-label">Select campaign type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={campaignType}
                            label="Select campaign type"
                            onChange={handleChange}
                        >
                            <MenuItem value={'single_blast'}>Single Blast</MenuItem>
                            <MenuItem value={'scheduled'}>Scheduled</MenuItem>
                        </Select>
                    </FormControl>

                    <Divider style={{ marginTop: 30 }}></Divider>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onCreateCampaign} size="large" style={{ marginTop: 20 }} variant="contained">Create</Button>
                    </Box>
                </Box>

            </Modal>
        </div>
    )
}
