export const MenuItems = [
  {
    admin: false,
    name: "Dashboard",
    path: "/admin",
    icon: "fa-solid fa-house",
  },
  {
    admin: true,
    name: "IQ",
    path: "/admin/iq",
    icon: "fa-solid fa-chart-pie",
  },
  {
    admin: false,
    name: "Messages",
    path: "/admin/messages",
    icon: "fa-regular fa-comment",
  },
  {
    admin: false,
    name: "Campaigns",
    path: "/admin/campaigns",
    icon: "fa-solid fa-bullhorn",
  },
  {
    admin: false,
    name: "Contacts",
    path: "/admin/contacts",
    icon: "fa-solid fa-address-book",
  },
  {
    admin: false,
    name: "Reports",
    path: "/admin/reports",
    icon: "fa-solid fa-users",
  },
  // {
  //   name: "Lookups",
  //   path: "/admin/lookups",
  //   icon: "fa-solid fa-magnifying-glass"
  // },
  {
    admin: false,
    name: "Workspace",
    path: "/admin/workspace",
    icon: "fa-solid fa-gear"
  },
];
