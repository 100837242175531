import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {toast} from "react-toastify";
import {KeywordsState} from "./keywords.slice";

export const getKeyword = createAsyncThunk(
    "/keywords/get",
    async ({}: any, { rejectWithValue }) => {
        try {
            const response = await Request.get('keywords')
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getKeywords = createAsyncThunk(
    "/keywords/getAll",
    async (_, { getState, rejectWithValue }: any) => {
        try {
            const { offset, limit } = getState().keywords as KeywordsState
            const response = await Request.get(`keywords?offset=${offset}&limit=${limit}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createKeyword = createAsyncThunk(
    "/keywords/create",
    async (payload: any, { rejectWithValue }) => {
        try {
            const response = await Request.post('keywords', payload)
            toast('Successful creation', { type: 'success' })
            return response
        } catch (error: any) {
            toast('Error during creation', { type: 'error' })
            return rejectWithValue(error);
        }
    }
);

export const updateKeyword = createAsyncThunk(
    "/keywords/update",
    async ({ id, payload }: any, { rejectWithValue }) => {
        try {
            const response = await Request.post(`keywords/${id}`, payload)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteKeyword = createAsyncThunk(
    "/keywords/delete",
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await Request.delete(`keywords/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
