import React, { useState } from "react";
import {
    Box,
    IconButton,
    InputAdornment,
    SelectChangeEvent, TextField,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SimStatTable} from "./utils";
import { useDispatch, useSelector } from "../../../redux/store";
import {toast} from "react-toastify";
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {CardIQ} from "./components/CardIQ";
import {SIMBoardIQ} from "./components/SIMBoardIQ";
import {
    getAnalyzeCarriers,
    getAnalyzeListServer,
    getAnalyzeReset,
    getAnalyzeStats, getDevices
} from "../../../redux/analyze/analyze.request";
import {fetchListSimWithPagination} from "../../../redux/analyze/analyze.slice";


dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

export const IQ = () => {

    const { listSim, columns, carriers, stats, devices, offset, limit, totalCount  } = useSelector(store => store.analyze);

    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [selectedDevice, setSelectedDevice] = useState<string>("");

    const handleDeviceChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setSelectedDevice(value);
        dispatch(getAnalyzeListServer({dev: value}))
    };

    const handleReload = () => {
        dispatch(getAnalyzeListServer({}))
        dispatch(getAnalyzeCarriers())
        dispatch(getAnalyzeStats())
        dispatch(getDevices())
    };

    const handlePageChange = (newOffset: number, newLimit: number) => {
        dispatch(fetchListSimWithPagination({ offset: newOffset, limit: newLimit }));
        dispatch(getAnalyzeListServer({}))
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        dispatch(getAnalyzeListServer({phone: value}))
    };

    const handleButtonClick = () => {
        console.log(selectedDevice)
        if(!selectedDevice){
            toast('Please select device', {type: 'error'})
        }
        dispatch(getAnalyzeReset({id: selectedDevice}))
    };

    const onFilterCard = (keyFilter: string) => {
        dispatch(getAnalyzeListServer({filter: keyFilter}))
    }

    return (
        <Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px'
                }}
            >


                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search numbers..."
                    value={searchValue}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon="search" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleReload}>
                                    <FontAwesomeIcon icon="sync" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: 220 }}
                />

            </Box>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        {stats.map((cardProps, index) => (
                            <CardIQ key={index} {...cardProps} onFilterCard={onFilterCard} />
                        ))}
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                }}>
                    <SIMBoardIQ carriers={carriers}></SIMBoardIQ>
                </Box>

            </Box>

            <Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 1
                    }}
                >
                    <SimStatTable
                        data={listSim}
                        columns={columns}
                        offset={offset}
                        limit={limit}
                        totalCount={totalCount}
                        devices={devices}
                        onPageChange={handlePageChange}
                        handleDeviceChange={handleDeviceChange}
                        selectedDevice={selectedDevice}
                        handleButtonClick={handleButtonClick}
                    />
                </Box>

            </Box>

        </Box>
    )
}
