import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    Stack,
    TextField,
    Typography,
    MenuItem,
    Switch
} from "@mui/material"
import './index.scss'
import { Colors } from "../../../types"
import { useDispatch, useSelector } from '../../../redux/store'
import React, { useCallback, useEffect, useState } from 'react'
import {getChats, loadMoreChats, unreadChat, updateChat} from '../../../redux/chat/chat.request'
import moment from 'moment'
import { ChangeChatUser } from './utils'
import { useAuth } from '../../../services/authContext'
import { Request } from '../../../redux/request'
import ExcelJS from 'exceljs'
import {socket} from "../../../services/socket";
import {IChat} from "../../../redux/chat/chat.interface";
import {onUpdateUnreadChat} from "../../../redux/chat/chat.slice";

type Props = {
    showTrash: boolean,
    setShowTrash: React.Dispatch<React.SetStateAction<boolean>>,
    showAll: boolean,
    setShowAll: React.Dispatch<React.SetStateAction<boolean>>,
    chats: IChat[],
    setChatId: any,
    text: string,
    setText: (str: string) => void
    campaignId: string
    setCampaignId: (id: string) => void
    onlyReplied: boolean,
    setOnlyReplied: React.Dispatch<React.SetStateAction<boolean>>,
    onlyUnread: boolean,
    setOnlyUnread: React.Dispatch<React.SetStateAction<boolean>>,
    sortByOldest: boolean,
    setSortByOldest: React.Dispatch<React.SetStateAction<boolean>>,
    userId: string,
    setUserId: (id: string) => void,
    checkSIM: {totalListNumber: any[]},
    setIsUpdateChats:  (isUpdate: boolean) => void
}

export const ChatsList = ({
                              chats, setChatId, text, setText, showTrash, setShowTrash, showAll, setShowAll, campaignId, setCampaignId,
                              onlyReplied, setOnlyReplied, onlyUnread, setOnlyUnread, sortByOldest, setSortByOldest, checkSIM, userId, setUserId,
                              setIsUpdateChats
                          }: Props) => {
    const { me } = useAuth()
    const { select } = useSelector(store => store.campaign)
    const { users } = useSelector(store => store.user)

    const [isChangeUser, setisChangeUser] = useState<{id: string, firstname: string, lastname: string, chat_id: string} | null>(null)
    const [horizontalScroll, setHorizontalScroll] = useState(0)

    const dispatch = useDispatch()

    const onUpdateChat = (id: string, payload: any) => {
        setIsUpdateChats(true)
        dispatch(updateChat({ id, payload }))
    }

    const handleScroll = useCallback(
        (event: any) => {
            const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 0.5;

            if (scrollLeft !== horizontalScroll) {
                setHorizontalScroll(scrollLeft);
                return;
            }

            if (isAtBottom) {
                dispatch(
                    loadMoreChats({ showTrash, campaignId, showAll, sortByOldest, onlyReplied, onlyUnread })
                )
                    .unwrap()
                    .then(() => {
                        socket.emit('check_sim', me);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
        [dispatch, horizontalScroll, showTrash, campaignId, showAll, sortByOldest, onlyReplied, onlyUnread, me]
    );

    const downloadChat = async (id: string) => {
        const { chat } = await Request.get(`chats/${id}`)

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");

        sheet.columns = [
            { header: "message", key: "message", width: 40 },
            { header: "direction", key: "direction", width: 10,},
            { header: "time", key: "time", width: 20,},
            { header: "number", key: "number", width: 15,},
            { header: "system_number", key: "system_number", width: 15,},
            { header: "name", key: "name", width: 25,},
            { header: "id", key: "id", width: 45,},
            { header: "conversation_id", key: "conversation_id", width: 45,},
        ];

        chat.messages?.map((it: any) => {
            sheet.addRow({
                message: it?.text,
                direction: it?.direction,
                time: moment(it?.createdAt).format('MM/DD/YYYY h:mm A'),
                number: it?.chat_number,
                system_number: chat.sending_number,
                name: it?.contact_name,
                id: it?.id,
                conversation_id: chat.id,
            });
        })

        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "download.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    }

    const chatsFilter = onlyReplied
        ? chats.filter(it => it.messages?.some(its => its.direction === 'outgoing'))
        : chats;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [option, setOption] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setIsOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const onUnread = (id: string) => {
        dispatch(onUpdateUnreadChat({id}))
    }

    return (
        <div style={{padding: 10, paddingRight: 20, height: '100%', position: 'relative'}}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <Typography>Channels: {chats.length}</Typography>
                    <Typography>Unread: {chats.filter(chat => chat.have_unseen_sms).length}</Typography>
                </Box>

                <div className="list_show_archive">
                    {me?.role === 'manager' &&
                        <Button onClick={() => setShowAll(prev => !prev)} size="small" variant="contained">
                            <FontAwesomeIcon color={showAll ? 'yellow' : 'white'}
                                             icon={"fa-solid fa-eye".split(' ') as any}></FontAwesomeIcon>
                        </Button>}
                    <Button onClick={() => setShowTrash(prev => !prev)} size="small" variant="contained">
                        <FontAwesomeIcon color={showTrash ? 'red' : 'white'}
                                         icon={"fa-solid fa-box".split(' ') as any}></FontAwesomeIcon>
                    </Button>
                </div>
            </Box>

            <Box sx={{display: 'flex', alignItems: 'center', gap: 4, marginTop: 2}}>
                <Box>
                    <Typography>Replied</Typography>
                    <Switch checked={onlyReplied} onChange={e => setOnlyReplied(e.target.checked)}></Switch>
                </Box>
                <Box>
                    <Typography>Unread</Typography>
                    <Switch checked={onlyUnread} onChange={e => setOnlyUnread(e.target.checked)}></Switch>
                </Box>
                <Box>
                    <Typography>Oldest</Typography>
                    <Switch checked={sortByOldest} onChange={e => setSortByOldest(e.target.checked)}></Switch>
                </Box>
            </Box>

            <TextField value={text} onChange={e => setText(e.target.value)} placeholder="Search" style={{marginTop: 8}}
                       fullWidth></TextField>

            <Box sx={{display: 'flex', alignItems: 'center', gap: 4}}>
                Campaign
                <FormControl style={{marginTop: 10, width: '100%',}}>
                    <InputLabel sx={{color: Colors.MAIN_LIGTH,}} id="demo-simple-select-label">Select
                        Campaign</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Campaign"
                        value={campaignId}
                        onChange={e => setCampaignId(e.target.value as string)}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {
                            select.map(it => <MenuItem key={it.id} value={it.id}>{it.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Box>

            {(me?.role === 'admin' || me?.role === 'manager') && <Box sx={{display: 'flex', alignItems: 'center', gap: 4}}>

                Users
                <FormControl style={{marginTop: 10, width: '100%',}}>
                    <InputLabel sx={{color: Colors.MAIN_LIGTH,}} id="demo-simple-select-label">Select User</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Campaign"
                        value={userId}
                        onChange={e => setUserId(e.target.value as string)}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {
                            users.map(it => <MenuItem key={it.id} value={it.id}>{it.firstname} {it.lastname}</MenuItem>)
                        }
                    </Select>
                </FormControl>

            </Box>}

            <div onScroll={handleScroll} className="list_items">
                {chatsFilter.map((chat, index) => {
                    let formattedNumber = chat.number.length === 10 && !chat.number.startsWith('+')
                        ? `+1${chat.number}`
                        : chat.number.startsWith('+')
                            ? chat.number
                            : `+${chat.number}`;

                    let formattedSendingNumber = chat.sending_number.length === 10 && !chat.sending_number.startsWith('+')
                        ? `+1${chat.sending_number}`
                        : chat.sending_number.startsWith('+')
                            ? chat.sending_number
                            : `+${chat.sending_number}`;

                    const formattedChatNumber = chat.sending_number.replace(/\D/g, '');
                    const simInfo = checkSIM.totalListNumber.find((sim: any) => sim.sn === formattedChatNumber);

                    const getStatusInfo = (status: string) => {
                        // console.log('status', status)
                        switch (status) {
                            case 'online':
                                return { backgroundColor: 'green', title: 'online' };
                            case 'locked':
                                return { backgroundColor: 'yellow', title: 'locked' };
                            case 'offline':
                                return { backgroundColor: 'red', title: 'offline' };
                            default:
                                return { backgroundColor: 'red', title: 'Unknown status' };
                        }
                    };

                    const statusInfo = simInfo
                        ? getStatusInfo(simInfo.status)
                        : { backgroundColor: 'red', title: 'offline' };



                    return (
                        <Box key={`${chat.id}${index}`}
                             sx={{marginTop: 1, display: 'flex', gap: 2, borderBottom: '1px solid grey'}}>

                            <Stack
                                sx={{ backgroundColor: statusInfo.backgroundColor, width: '5px', cursor: 'help' }}
                                title={statusInfo.title}
                            ></Stack>

                            <Stack marginTop={1} alignItems={'center'}>
                                {/* <Box sx={{ cursor: 'pointer' }}>{<FontAwesomeIcon color='yellow' icon={"fa-brands fa-bitbucket".split(' ') as any}></FontAwesomeIcon>}</Box>
                                    <Box sx={{ cursor: 'pointer' }}>{<FontAwesomeIcon icon={"fa-solid fa-square-plus".split(' ') as any}></FontAwesomeIcon>}</Box> */}
                                <Box sx={{cursor: 'pointer'}}>{<FontAwesomeIcon onClick={() => downloadChat(chat.id)} icon={"fa-solid fa-cloud-arrow-down".split(' ') as any}></FontAwesomeIcon>}</Box>
                                <Box sx={{cursor: 'pointer'}}>{<FontAwesomeIcon onClick={() => onUnread(chat.id)}
                                    icon={"fa-solid fa-envelope-circle-check".split(' ') as any}
                                ></FontAwesomeIcon>}</Box>
                            </Stack>


                            <Stack width={'100%'} direction={'column'} sx={{cursor: 'pointer'}} onClick={() => setChatId({id: chat.id, number: chat.number})}>
                                <Box sx={{display: 'flex', gap: 2}}>
                                    {formattedNumber.length === 10
                                        ? <Typography variant="subtitle2">
                                            {formattedNumber.slice(0, 3)} {formattedNumber.slice(3, 6)} {formattedNumber.slice(6, 10)}
                                        </Typography>
                                        : <Typography variant="subtitle2">
                                            {formattedNumber.slice(0, 2)} {formattedNumber.slice(2, 5)} {formattedNumber.slice(5, 8)} {formattedNumber.slice(8, 12)}
                                        </Typography>
                                    }
                                    {formattedSendingNumber.length === 10
                                        ? <Typography variant="caption">Sending:
                                            {formattedSendingNumber.slice(0, 3)} {formattedSendingNumber.slice(3, 6)} {formattedSendingNumber.slice(6, 10)}
                                        </Typography>
                                        : <Typography variant="caption">Sending:
                                            {formattedSendingNumber.slice(0, 2)} {formattedSendingNumber.slice(2, 5)} {formattedSendingNumber.slice(5, 8)} {formattedSendingNumber.slice(8, 12)}
                                        </Typography>
                                    }
                                </Box>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 6}}
                                     >
                                    <Box>
                                        {chat.have_unseen_sms && <div
                                            style={{
                                                height: 8,
                                                width: 8,
                                                borderRadius: 50,
                                                backgroundColor: 'green'
                                            }}></div>}
                                    </Box>

                                    <Typography
                                        variant="caption">{chat.messages?.length && chat.messages[chat.messages.length - 1].text}</Typography>
                                </div>
                                {chat.messages?.length && <Typography
                                    variant="caption">{moment(chat.messages[chat.messages.length - 1].createdAt).format('MM/DD/YYYY h:mm A')}</Typography>}
                            </Stack>

                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Box sx={{
                                    height: 24, width: 24, backgroundColor: 'blue',
                                    borderRadius: 50, fontSize: 12, alignSelf: 'flex-end', color: 'wheat',
                                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '4px'
                                }}>
                                    {chat.user ? `${chat.user?.lastname?.slice(0, 1)}${chat.user?.firstname?.slice(0, 1)}`
                                        : chat.contact_name.slice(0, 2)
                                    }
                                </Box>

                                <Stack display={'flex'} alignItems={'center'} direction={'row'} gap={1}>

                                    {me?.role !== 'staff' && (
                                        // <Box sx={{cursor: 'pointer'}}>{<FontAwesomeIcon
                                        //     onClick={() => setisChangeUser({...chat.user, chat_id: chat.id})}
                                        //     icon={"fa-solid fa-people-group".split(' ') as any}></FontAwesomeIcon>}
                                        // </Box>
                                        <Box>
                                            <Box sx={{ cursor: 'pointer', display: 'inline-block' }}
                                                 onClick={(event) => {
                                                     handleOpen(event);
                                                     setisChangeUser({...chat.user, chat_id: chat.id});
                                                 }}>
                                                <FontAwesomeIcon icon={"fa-solid fa-people-group".split(' ') as any} />
                                            </Box>
                                            {isChangeUser && (
                                                <ChangeChatUser
                                                    open={isChangeUser}
                                                    close={() => setisChangeUser(null)}
                                                    anchorEl={anchorEl}
                                                    setAnchorEl={setAnchorEl}
                                                    option={option}
                                                    isOpen={isOpen}
                                                    setIsOpen={setIsOpen}
                                                    setOption={setOption}
                                                />
                                            )}
                                        </Box>

                                    )}

                                    <Box sx={{cursor: 'pointer'}}>{<FontAwesomeIcon
                                        onClick={() => onUpdateChat(chat.id, {is_top: !chat.is_top})}
                                        color={chat.is_top ? 'orange' : ''}
                                        icon={"fa-solid fa-flag".split(' ') as any}></FontAwesomeIcon>}
                                    </Box>

                                    <Box sx={{cursor: 'pointer'}}>{<FontAwesomeIcon
                                        onClick={() => onUpdateChat(chat.id, {is_trash: !chat.is_trash})}
                                        color={chat.is_trash ? 'red' : ''}
                                        icon={"fa-solid fa-box".split(' ') as any}></FontAwesomeIcon>}
                                    </Box>

                                </Stack>
                            </Box>
                        </Box>
                    )
                })}
            </div>

            {/*Old modal*/}
            {/*{isChangeUser && <ChangeChatUser open={isChangeUser} close={() => setisChangeUser(null)}></ChangeChatUser>}*/}
        </div>
    )
}