import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {AnalyzeState} from "./analyze.slice";

export const getAnalyzeListServer = createAsyncThunk(
    "/analyze/list/server",
    async ({phone, dev, filter}: {phone?: string, dev?: string, filter?: string}, { getState, rejectWithValue }: any) => {
        try {
            const { offset, limit } = getState().analyze as AnalyzeState
            const response = await Request.get(`analyze/list/server?offset=${offset}&limit=${limit}${(phone) ? `&phone=${phone}` : ''}${dev ? `&dev=${dev}` : ''}${filter ? `&filter=${filter}` : ''}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAnalyzeCarriers = createAsyncThunk(
    "/analyze/get/carriers",
    async (_, { rejectWithValue }: any) => {
        try {
            const response = await Request.get(`analyze/get/carriers`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAnalyzeStats = createAsyncThunk(
    "/analyze/get/stats",
    async (_, { rejectWithValue }: any) => {
        try {
            const response = await Request.get(`analyze/get/stats`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getDevices = createAsyncThunk(
    "/analyze/get/devices",
    async (_, { rejectWithValue }: any) => {
        try {
            const response = await Request.get(`analyze/get/devices`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAnalyzeReset = createAsyncThunk(
    "/analyze/reset/",
    async ({id}: {id: string}, { rejectWithValue }: any) => {
        try {
            const response = await Request.post(`analyze/reset/${id}`)
            return response
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
