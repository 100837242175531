import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {
    contactFilterChannels,
    contactFilterMaster,
    contactSelectChannels,
    createCampaign, deleteAttachment,
    deleteCampaignLists,
    getAllCampaigns,
    getAttachments,
    getCampaign,
    getCampaignLists,
    getCampaignOne,
    getCampaigns,
    getListDetailsCampaign,
    importCampaignContacts,
    pauseCampaign,
    recoverListContacts,
    removeAttachment,
    removeListContacts,
    resendCampaign,
    selectAttachment,
    sendCampaignSingle,
    unrepliedCampaign,
    updateCampaign,
    updateCampaignTemplates,
    updateCampaignUser,
    uploadCampaignAttachment
} from "./campaign.request";
import {IAttachments, ICampaign, IDetails, IDetailsMobileOperator} from "./campaign.interface";


export type CampaignState = {
    campaign: ICampaign | null;
    listDetails: IDetailsMobileOperator[] | [];
    campaigns: ICampaign[];
    lists: { list_name: string, list_id: string }[]
    select: ICampaign[] | []
    attachments: IAttachments[] | []
    limit: number;
    offset: number;
    isLoading: boolean;
    isLoadingSendCampaign: boolean;
    isLoadingRemoveList: boolean;
    isLoadingGetDetails: boolean;
    isLoadingRecoverList: boolean;
    isLoadingFilter: boolean;
};

const initialState: CampaignState = {
    campaign: null,
    listDetails: [],
    campaigns: [],
    lists: [],
    select: [],
    attachments: [],
    limit: 16,
    offset: 0,
    isLoading: false,
    isLoadingSendCampaign: false,
    isLoadingRemoveList: false,
    isLoadingGetDetails: false,
    isLoadingRecoverList: false,
    isLoadingFilter: false
};


const campaignSlice = createSlice({
    name: "campaign",
    initialState,
    reducers: {
        clearCampaign: (state) => {
            state.campaign = null
        },
        clearCampaigns: (state) => {
            state.campaigns = []
            state.offset = 0
        },
        archiveCampaignFilter: (state, action) => {
            const id: string = action.payload
            state.campaigns = current(state.campaigns).filter(campaign => campaign.id !== id)
        },
        onCampaignUpdate: (state, action) => {
            const data: any = action.payload.sms
            state.campaigns = current(state.campaigns.map((it) => it.id === data?.campaign_id ? {
                ...it,
                total_answered: data.responsed ? it.total_answered + 1 : it.total_answered,
                stop: data.stop ? it.stop + 1 : it.stop
            } : it))
        },
        onEditCampaignTitle: (state, action: PayloadAction<{ title: string }>) => {
            // console.log('action.payload', action.payload)
            if (state.campaign) {
                state.campaign.name = action.payload.title;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            // selectAttachment
            .addMatcher(
                (action) => action.type === selectAttachment.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === selectAttachment.fulfilled.type,
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === selectAttachment.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // uploadCampaignAttachment
            .addMatcher(
                (action) => action.type === uploadCampaignAttachment.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === uploadCampaignAttachment.fulfilled.type,
                (state, action: PayloadAction<{ files: string[], campaignId: string }>) => {
                    state.campaigns.map((c: ICampaign) => {
                        if(c.id === action.payload.campaignId) {
                            c.attachment = action.payload.files[0]
                        }
                    })
                    if(state.campaign) state.campaign.attachment = action.payload.files[0]
                    //uploadNewFile = action.payload.files
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === uploadCampaignAttachment.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // deleteAttachment
            .addMatcher(
                (action) => action.type === deleteAttachment.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === deleteAttachment.fulfilled.type,
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === deleteAttachment.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // removeCampaignAttachment
            .addMatcher(
                (action) => action.type === removeAttachment.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === removeAttachment.fulfilled.type,
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === removeAttachment.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // getAttachments
            .addMatcher(
                (action) => action.type === getAttachments.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getAttachments.fulfilled.type,
                (state, action: PayloadAction<{ attachments: IAttachments[] }>) => {
                    state.attachments = action.payload.attachments
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getAttachments.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get one
            .addMatcher(
                (action) => action.type === getCampaignOne.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getCampaignOne.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    const updatedCampaign = action.payload.campaign;
                    state.campaign = updatedCampaign;
                    state.campaigns = current(state.campaigns).map(campaign =>
                        campaign.id === updatedCampaign.id ? updatedCampaign : campaign
                    );
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getCampaignOne.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get
            .addMatcher(
                (action) => action.type === getCampaign.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getCampaign.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    state.campaign = action.payload.campaign
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getCampaign.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // contactSelectChannels
            .addMatcher(
                (action) => action.type === contactSelectChannels.pending.type,
                (state) => {
                    state.isLoadingFilter = true;
                }
            )
            .addMatcher(
                (action) => action.type === contactSelectChannels.fulfilled.type,
                (state, action) => {
                    state.isLoadingFilter = false;
                }
            )
            .addMatcher(
                (action) => action.type === contactSelectChannels.rejected.type,
                (state, action: any) => {
                    state.isLoadingFilter = false;
                }
            )
            // contactFilterChannels
            .addMatcher(
                (action) => action.type === contactFilterChannels.pending.type,
                (state) => {
                    state.isLoadingFilter = true;
                }
            )
            .addMatcher(
                (action) => action.type === contactFilterChannels.fulfilled.type,
                (state, action) => {
                    state.isLoadingFilter = false;
                }
            )
            .addMatcher(
                (action) => action.type === contactFilterChannels.rejected.type,
                (state, action: any) => {
                    state.isLoadingFilter = false;
                }
            )
            // contactFilterMaster
            .addMatcher(
                (action) => action.type === contactFilterMaster.pending.type,
                (state) => {
                    state.isLoadingFilter = true;
                }
            )
            .addMatcher(
                (action) => action.type === contactFilterMaster.fulfilled.type,
                (state, action) => {
                    state.isLoadingFilter = false;
                }
            )
            .addMatcher(
                (action) => action.type === contactFilterMaster.rejected.type,
                (state, action: any) => {
                    state.isLoadingFilter = false;
                }
            )


            // get all
            .addMatcher(
                (action) => action.type === getCampaigns.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getCampaigns.fulfilled.type,
                (state, action: PayloadAction<{ campaigns: ICampaign[] }>) => {

                    state.campaigns = [...current(state.campaigns), ...action.payload.campaigns]

                    const totalLength = current(state).offset + action.payload.campaigns.length + 1;
                    let offset = current(state).offset + action.payload.campaigns.length + 1;
                    if (offset > totalLength)
                        offset = totalLength;

                    state.offset = offset || current(state).limit
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getCampaigns.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get all t
            .addMatcher(
                (action) => action.type === getAllCampaigns.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getAllCampaigns.fulfilled.type,
                (state, action: PayloadAction<{ campaigns: ICampaign[] }>) => {
                    state.select = action.payload.campaigns
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getAllCampaigns.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // create
            .addMatcher(
                (action) => action.type === createCampaign.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === createCampaign.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    state.campaigns = [action.payload.campaign, ...current(state.campaigns)]
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === createCampaign.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get list details
            .addMatcher(
                (action) => action.type === getListDetailsCampaign.pending.type,
                (state) => {
                    state.isLoadingGetDetails = true;
                }
            )
            .addMatcher(
                (action) => action.type === getListDetailsCampaign.fulfilled.type,
                (state, action: PayloadAction<IDetails>) => {
                    state.listDetails = action.payload.lists;
                    state.isLoadingGetDetails = false;
                }
            )
            .addMatcher(
                (action) => action.type === getListDetailsCampaign.rejected.type,
                (state, action: any) => {
                    state.isLoadingGetDetails = false;
                }
            )
            // update
            .addMatcher(
                (action) => action.type === updateCampaign.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateCampaign.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    if (state.campaign) {
                        const campaign = current(state.campaign)
                        if (campaign?.id === action.payload.campaign.id) state.campaign = {...state.campaign, ...action.payload.campaign}
                    }
                    state.campaigns = current(state).campaigns.map(campaign => campaign.id === action.payload.campaign.id ? action.payload.campaign : campaign)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === updateCampaign.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // update user
            .addMatcher(
                (action) => action.type === updateCampaignUser.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateCampaignUser.fulfilled.type,
                (state, action: PayloadAction<{ user_id: string, campaign_id: string, is_added: boolean }>) => {
                    if (state.campaign) {
                        state.campaign.users = current(state.campaign).users.map(user =>
                            user.campaign_id === action.payload.campaign_id && user.user_id === action.payload.user_id
                                ? {...user, is_added: action.payload.is_added} : user
                        )
                    }
                }
            )
            .addMatcher(
                (action) => action.type === updateCampaignUser.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // update templates
            .addMatcher(
                (action) => action.type === updateCampaignTemplates.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateCampaignTemplates.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    if (state.campaign) {
                        state.campaign = action.payload.campaign
                    }
                }
            )
            .addMatcher(
                (action) => action.type === updateCampaignTemplates.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // pause
            .addMatcher(
                (action) => action.type === pauseCampaign.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === pauseCampaign.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    state.campaigns = current(state.campaigns).map(campaign => campaign.id === action.payload.campaign.id ? action.payload.campaign : campaign)

                }
            )
            .addMatcher(
                (action) => action.type === pauseCampaign.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // import
            .addMatcher(
                (action) => action.type === importCampaignContacts.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === importCampaignContacts.fulfilled.type,
                (state, action: PayloadAction<{ contacts_length: number }>) => {
                    if (state.campaign) {
                        state.campaign.total_contacts = action.payload.contacts_length
                    }
                }
            )
            .addMatcher(
                (action) => action.type === importCampaignContacts.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // resend
            .addMatcher(
                (action) => action.type === resendCampaign.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === resendCampaign.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    if (state.campaigns) {

                        state.campaigns = [action.payload.campaign, ...current(state.campaigns)]
                        state.isLoading = false;
                    }
                }
            )
            .addMatcher(
                (action) => action.type === resendCampaign.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // unreplied
            .addMatcher(
                (action) => action.type === unrepliedCampaign.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === unrepliedCampaign.fulfilled.type,
                (state, action: PayloadAction<{ campaign: ICampaign }>) => {
                    if (state.campaigns) {

                        state.campaigns = [action.payload.campaign, ...current(state.campaigns)]
                        state.isLoading = false;
                    }
                }
            )
            .addMatcher(
                (action) => action.type === unrepliedCampaign.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )

            // send
            .addMatcher(
                (action) => action.type === sendCampaignSingle.pending.type,
                (state) => {
                    state.isLoadingSendCampaign = true;
                }
            )
            .addMatcher(
                (action) => action.type === sendCampaignSingle.fulfilled.type,
                (state, action: PayloadAction<{ id: string }>) => {
                    if (state.campaign) {
                        state.campaign = {...current(state.campaign), status: 'pending'}
                    }

                    state.campaigns = current(state.campaigns).map(campaign => campaign.id === action.payload.id ? {
                        ...campaign,
                        status: 'pending'
                    } : campaign)

                    state.isLoadingSendCampaign = false;
                }
            )
            .addMatcher(
                (action) => action.type === sendCampaignSingle.rejected.type,
                (state, action: any) => {
                    state.isLoadingSendCampaign = false;
                }
            )
            // send
            .addMatcher(
                (action) => action.type === getCampaignLists.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getCampaignLists.fulfilled.type,
                (state, action: PayloadAction<{ lists: { list_name: string, list_id: string }[] }>) => {
                    if (state.campaign) {
                        state.lists = action.payload.lists
                        state.isLoading = false;
                    }
                }
            )
            .addMatcher(
                (action) => action.type === getCampaignLists.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // remove contact list
            .addMatcher(
                (action) => action.type === removeListContacts.pending.type,
                (state) => {
                    state.isLoadingRemoveList = true;
                }
            )
            .addMatcher(
                (action) => action.type === removeListContacts.fulfilled.type,
                (state) => {
                    state.isLoadingRemoveList = false;
                }
            )
            .addMatcher(
                (action) => action.type === removeListContacts.rejected.type,
                (state, action: any) => {
                    state.isLoadingRemoveList = false;
                }
            )
            // recover contacts list
            .addMatcher(
                (action) => action.type === recoverListContacts.pending.type,
                (state) => {
                    state.isLoadingRecoverList = true;
                }
            )
            .addMatcher(
                (action) => action.type === recoverListContacts.fulfilled.type,
                (state) => {
                    state.isLoadingRecoverList = false;
                }
            )
            .addMatcher(
                (action) => action.type === recoverListContacts.rejected.type,
                (state, action: any) => {
                    state.isLoadingRecoverList = false;
                }
            )
            // delete
            .addMatcher(
                (action) => action.type === deleteCampaignLists.pending.type,
                (state) => {
                    state.isLoadingRemoveList = true;
                }
            )
            .addMatcher(
                (action) => action.type === deleteCampaignLists.fulfilled.type,
                (state, action: PayloadAction<{ id: string, contacts: number }>) => {
                    if (state.campaign) {
                        state.lists = current(state.lists).filter(list => list.list_id !== action.payload.id)
                        state.campaign = {...(current(state.campaign)), total_contacts: action.payload.contacts}
                        state.isLoading = false;
                    }
                }
            )
            .addMatcher(
                (action) => action.type === deleteCampaignLists.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
        // // update template
        // .addMatcher(
        //   (action) => action.type === updateCampaignTemplate.pending.type,
        //   (state) => {
        //     state.isLoading = true;
        //   }
        // )
        // .addMatcher(
        //   (action) => action.type === updateCampaignTemplate.fulfilled.type,
        //   (state, action: PayloadAction<{campaign: ICampaign}>) => {
        //     if(state.campaign) {
        //       state.campaign = action.payload.campaign
        //     }
        //   }
        // )
        // .addMatcher(
        //   (action) => action.type === updateCampaignTemplate.rejected.type,
        //   (state, action: any) => {
        //     state.isLoading = false;
        //   }
        // )
    },
});

export const {clearCampaign, clearCampaigns, archiveCampaignFilter, onCampaignUpdate, onEditCampaignTitle} = campaignSlice.actions;

export default campaignSlice.reducer;
