import {
    Box,
    Card,
    Dialog, DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {useDispatch} from "../../../redux/store";
import {sortTable} from "../../../redux/dashboard/dashboard.slice";

export type CardInfoDashboardProps = {
    title: string;
    value: number;
    icon: React.ReactNode;
};

export type Column = {
    header: string;
    key: string;
    sort: 'ASC' | 'DESC';
};

export type CardListInfoDashboardProps = {
    tableIndex: string;
    title: string;
    columns: Column[];
    data: any[];
    maxRowsToShow?: number;
};



export const CardInfoDashboard = ({ title, value, icon }: CardInfoDashboardProps) => {
    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
            <Box>{title}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', fontSize: '35px' }}>
                <Box>{icon}</Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h4"><span>+</span>{value}</Typography>
                </Box>
            </Box>
        </Card>
    );
};

export const CardListInfoDashboard = ({
                                          title,
                                          columns,
                                          tableIndex,
                                          data,
                                          maxRowsToShow = 3,
                                      }: CardListInfoDashboardProps) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSorting = (tableIndex: string, columnKey: string) => {
        dispatch(sortTable({ tableIndex, columnKey }));
    };

    const displayedData = data.slice(0, maxRowsToShow);

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                    cursor: "pointer",
                }}
                onClick={handleOpen}
            >
                <Typography variant="h6">{title}</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((col, index) => (
                                <TableCell key={index}>
                                    {col.header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedData.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {columns.map((col, colIndex) => (
                                    <TableCell key={colIndex}>{row[col.key]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {data.length > maxRowsToShow && (
                    <Typography variant="caption" align="right">
                        More {data.length - maxRowsToShow} items...
                    </Typography>
                )}
            </Card>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((col, index) => (
                                    <TableCell key={index}>
                                        {col.header}
                                        {
                                            col.sort === 'ASC' &&
                                            <FontAwesomeIcon
                                                cursor={"pointer"}
                                                onClick={() => onSorting(tableIndex, col.key)}
                                                style={{ fontSize: "15px", marginLeft: '5px', marginRight: '5px' }} size="3x"
                                                icon={"fa-solid fa-caret-up".split(' ') as any}></FontAwesomeIcon>
                                        }
                                        {
                                            col.sort === 'DESC' &&
                                            <FontAwesomeIcon
                                                cursor={"pointer"}
                                                onClick={() => onSorting(tableIndex, col.key)}
                                                style={{ fontSize: "15px", marginLeft: '5px', marginRight: '5px' }} size="3x"
                                                icon={"fa-solid fa-caret-down".split(' ') as any}></FontAwesomeIcon>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {columns.map((col, colIndex) => (
                                        <TableCell key={colIndex}>{row[col.key]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    );
};





