import { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, Box, Button, Grid, IconButton, Modal } from '@mui/material';
import { useDispatch, useSelector } from '../../../redux/store';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Colors } from '../../../types';
import { useTheme } from '../../../services/theme';
import {ModalLoadContacts, ModalSendCampaign} from './utils';
import { getTemplates } from '../../../redux/slices/templates';
import useDebounce from '../../../services/hooks';
import { useAuth } from '../../../services/authContext';
import { CampaignStaff } from './components/staff';
import { CampaignSchedule } from './components/schedule';
import { CampaignContacts } from './components/contacts';
import { CampaignTemplates } from './components/templates';
import { toast } from 'react-toastify';
import { Details } from './components/details';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    getCampaign,
    getListDetailsCampaign,
    pauseCampaign,
    recoverListContacts,
    removeListContacts,
    updateCampaign,
    updateCampaignTemplate,
    updateCampaignTemplates,
    uploadCampaignAttachment,
    getAttachments,
    selectAttachment,
    deleteAttachment,
    removeAttachment,
    contactFilterMaster,
    contactFilterChannels,
    contactSelectChannels,
} from '../../../redux/campaign/campaign.request';
import {clearCampaign, onEditCampaignTitle} from '../../../redux/campaign/campaign.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ImageUpload} from "./components/upload";

export const CampaignsForm = () => {
    const { me } = useAuth();
    const [isSendCampaignModal, setSetCampaignModal] = useState(false);
    const [message, setMessage] = useState('');
    const [filterMaster, setFilterMaster] = useState(false);
    const [filterChannels, setFilterChannels] = useState(false);
    const [file, setFile] = useState<any>();
    const debouncedMessage = useDebounce(message, 500);
    const { campaign, listDetails, isLoadingFilter, attachments } = useSelector((store) => store.campaign);
    const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
    const [selectedLists, setSelectedLists] = useState<any | null>([]);
    const [attachmentUrl, setAttachmentUrl] = useState<string | null>(null);
    const [sizeMBFile, setSizeMBFile] = useState<any>(null);
    const attachmentInputRef = useRef<any>(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const theme = useTheme();

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [localAttachments, setLocalAttachments] = useState<any[]>([]);

    const [editTitleCampaign, setEditTitleCampaign] = useState<boolean>(false);
    const [titleCampaign, setTitleCampaign] = useState<string>('');

    const onFilterMaster = (filterMaster: boolean) => {
        setFilterMaster(filterMaster);
        dispatch(contactFilterMaster({id, payload: {filter: filterMaster}}));
    }

    const onFilterChannels = (filterChannels: boolean) => {
        // console.log(filterChannels)
        setFilterChannels(filterChannels)
        dispatch(contactFilterChannels({id, payload: {filter: filterChannels}}));
    }

    const onSelectedChannels = (selectedLists: any[]) => {
        // console.log(selectedLists)
        setSelectedLists(selectedLists)
        dispatch(contactSelectChannels({id, payload: {filter: selectedLists}}));
    }

    useEffect(() => {
        // console.log(campaign)
        if(campaign) {
            setFilterMaster(campaign.is_filter_master);
            setFilterChannels(campaign.is_filter_channels);
            setSelectedLists(Array.isArray(campaign.selected_channels)
                ? campaign.selected_channels
                : []
            );
        }
    }, [campaign]);

    const loadCampaign = () => {
        if (id) {
            dispatch(getCampaign(id)).then((res) => {
                setSelectedTemplates(res.payload.campaign.templates.map((it: any) => it.id));
                let server_name = res.payload.campaign.file?.server_name;
                if (server_name) {
                    setAttachmentUrl((server_name) ? `${process.env.REACT_APP_IMAGE_URL}${server_name}`: null);
                }
            });

            dispatch(getListDetailsCampaign({ id }));
        }

        return () => {
            dispatch(clearCampaign());
        };
    }

    useEffect(() => {
        if(!isLoadingFilter && id) {
            dispatch(getCampaign(id))
        }
    }, [isLoadingFilter]);

    useEffect(() => {
        // Fetch attachments and set them to local state
        dispatch(getAttachments()).then((res) => {
            setLocalAttachments(res.payload.attachments);
        });
    }, [dispatch]);

    useEffect(() => {
        return loadCampaign()
    }, [id, dispatch]);

    useEffect(() => {
        if (me) dispatch(getTemplates({ user_id: me?.id }));
    }, [me, dispatch]);

    useEffect(() => {
        if (campaign?.message) setMessage(campaign?.message);
    }, [campaign?.message]);



    const removeList = async (dip_carrier: string) => {
        await dispatch(removeListContacts({ id, payload: { dip_carrier } }));
        await dispatch(getListDetailsCampaign({ id }));
        // await dispatch(updateCampaign({ id }));
    };

    const recoverList = async (dip_carrier: string) => {
        await dispatch(recoverListContacts({ id, payload: { dip_carrier } }));
        await dispatch(getListDetailsCampaign({ id }));
        // await dispatch(updateCampaign({ id }));
    };

    const handleSaveMessage = useCallback(() => {
        if (message && selectedTemplate) {
            setMessage('');
            dispatch(updateCampaignTemplate({ campaign_id: id, payload: { template_id: selectedTemplate.id, message } }));
        } else {
            dispatch(updateCampaign({ id, payload: { message } }));
        }
    }, [message, selectedTemplate, dispatch, id]);

    const handlePause = () => {
        dispatch(pauseCampaign({ id }));
    };

    useEffect(() => {
        // console.log(campaign)
        if(campaign)
            setAttachmentUrl((campaign.file) ? `${process.env.REACT_APP_IMAGE_URL}${campaign.file.server_name}` : null);
    }, [campaign]);

    const updateTemplates = (data: any) => {
        // console.log(data)
        setSelectedTemplates(data)
        dispatch(updateCampaignTemplates({ campaign_id: campaign?.id, payload: { template_ids: data } }));
    }

    if (!campaign)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 80px)' }}>
                <CircularProgress></CircularProgress>
            </Box>
        );

    const SendingButtons = () => {
        if (campaign.status === 'created')
            return (
                <>
                    {me?.role !== 'admin' && (
                        <Button onClick={() => setSetCampaignModal(true)} size="large" variant="contained">
                            SEND
                        </Button>
                    )}
                </>
            );
        if (campaign.status === 'pending')
            return (
                <>
                    <Button onClick={handlePause} variant="contained" style={{ display: 'flex', alignItems: 'center' }}>
                        Pause
                    </Button>
                </>
            );
        if (campaign.status === 'sended')
            return (
                <>
                    <Typography style={{ display: 'flex', alignItems: 'center' }}>SENT</Typography>
                </>
            );

        return (
            <>
                {me?.role !== 'admin' && (
                    <Button onClick={() => setSetCampaignModal(true)} size="large" variant="contained">
                        SEND
                    </Button>
                )}
            </>
        );
    };

    const handleRemoveAttachment = async () => {
        setAttachmentUrl(null);
        if (attachmentInputRef.current) {
            attachmentInputRef.current.value = '';
        }
        try {
            if (id) {
                await dispatch(removeAttachment({ id }));
                setAttachmentUrl(null);
            }
        } catch (error) {
            console.error('Error remove:', error);
            toast('Error remove file', { type: 'error' });
        }
    };

    const handleUpdate = (id: string, payload: any): void => {
        dispatch(updateCampaign({ id, payload }));
    };

    const handleOpenImageModal = () => {
        dispatch(getAttachments()).then((res) => {
            setLocalAttachments(res.payload.attachments);
            setIsImageModalOpen(true);
        });
    };

    const handleCloseImageModal = () => {
        setIsImageModalOpen(false);
    };

    const handleSelectImage = (fileId: string) => {
        const selectedFile = localAttachments.find((file) => file.id === fileId);
        if (selectedFile) {
            setAttachmentUrl((selectedFile.file) ? `${process.env.REACT_APP_IMAGE_URL}/${selectedFile.file.server_name}` : null);
            if(id) {
                dispatch(selectAttachment({id, payload: {idFile: fileId}})).then((res) => {
                    dispatch(getCampaign(id))
                });
            }
            setIsImageModalOpen(false);
        }
    };

    const handleDeleteImage = async (fileId: string) => {
        try {
            await dispatch(deleteAttachment({ id: fileId }));
            setLocalAttachments((prevAttachments) => prevAttachments.filter((file) => file.id !== fileId));
        } catch (error) {
            console.error('Error removing attachment:', error);
            toast('Error removing file', { type: 'error' });
        }
    };

    const resetImages = () => {
        if (attachmentInputRef.current) {
            attachmentInputRef.current.value = '';
        }
        setSizeMBFile(null);
        toast(
            'The file could not be added. Maximum allowed image dimensions are 3072x3072 pixels, and the file size must not exceed 500KB',
            { type: 'error' }
        );
    };

    const onChangeAttachment = async (event: any) => {
        const file = event.target.files[0];
        const sizeMB = Number((file.size / (1024 * 1024)).toFixed(2));

        setSizeMBFile(sizeMB);

        if (sizeMB > 0.5) {
            resetImages();
            return;
        }

        const img = new window.Image();
        img.src = URL.createObjectURL(file);

        img.onload = async () => {
            const width = img.width;
            const height = img.height;

            if (width > 3072 || height > 3072) {
                resetImages();
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            try {
                if (id) {
                    const response = await dispatch(uploadCampaignAttachment({ id, formData }));
                    const uploadedFilePath = response.payload?.filePath;
                    setAttachmentUrl(uploadedFilePath ? `${process.env.REACT_APP_IMAGE_URL}${uploadedFilePath}` : null);
                    dispatch(getCampaign(id))
                }
            } catch (error) {
                console.error('Error loading attachment:', error);
                toast('Error uploading the file', { type: 'error' });
            }
        };
    };


    const onEdit = () => {
        setEditTitleCampaign(true);
    }

    const onSaveEdit = () => {
        if (titleCampaign.trim() && titleCampaign !== campaign.name) {
            dispatch(onEditCampaignTitle({ title: titleCampaign }));
            handleUpdate(campaign.id, { name: titleCampaign });
        }
        setEditTitleCampaign(false);
    };
    const onSaveCancel = () => {
        setEditTitleCampaign(false);
    }

    const writeNewTitle = (title: string) => {
        setTitleCampaign(title);
    }

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Campaigns</Typography>
                <span> /{campaign?.name}</span>
            </Box>

            <Grid style={{ marginTop: 10 }} container spacing={2}>
                <Grid item xs={12} sm={10} md={10}>
                    <Box
                        sx={{
                            backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                            padding: 4,
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 2,
                        }}
                    >
                        <Box>
                            <Typography variant="subtitle1" display="flex" alignItems="center" gap={1}>
                                {editTitleCampaign ? (
                                    <>
                                        <input
                                            type="text"
                                            value={titleCampaign}
                                            onChange={(e) => writeNewTitle(e.target.value)}
                                            // onBlur={() => setEditTitleCampaign(false)}
                                            autoFocus
                                        />
                                        <Button onClick={onSaveEdit}>Save</Button>
                                        <Button onClick={onSaveCancel} variant="contained" color="error">Cancel</Button>
                                    </>
                                ) : (
                                    <>
                                        {campaign.name} - {campaign.type === 'single_blast' ? 'SINGLE_BLAST' : 'SCHEDULE'}
                                        <IconButton size="small" onClick={onEdit} aria-label="Edit campaign name">
                                            <FontAwesomeIcon icon={"fa-solid fa-edit".split(" ") as any} />
                                        </IconButton>
                                    </>
                                )}
                            </Typography>
                            <Typography variant="caption">
                                Here you can upload your list, select options for list, set text message, and permissions
                            </Typography>
                        </Box>
                        <SendingButtons></SendingButtons>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                    <Box
                        sx={{
                            backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            style={{
                                padding: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                            variant="subtitle2"
                        >
                            TOTAL CONTACTS <span>{campaign.total_contacts || 0}</span>
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <CampaignContacts
                        filterChannels={filterChannels}
                        filterMaster={filterMaster}
                        selectedLists={selectedLists}
                        setSelectedLists={setSelectedLists}
                        setFile={setFile}
                        setFilterChannels={setFilterChannels}
                        setFilterMaster={setFilterMaster}
                        onFilterMaster={onFilterMaster}
                        onFilterChannels={onFilterChannels}
                        onSelectedChannels={onSelectedChannels}
                    ></CampaignContacts>

                    {campaign.type === 'scheduled' && <CampaignSchedule campaign={campaign}></CampaignSchedule>}

                    <Details
                        listDetails={listDetails}
                        removeList={removeList}
                        recoverList={recoverList}
                    ></Details>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <ImageUpload
                        theme={theme}
                        attachmentUrl={attachmentUrl}
                        onChangeAttachment={onChangeAttachment}
                        handleOpenImageModal={handleOpenImageModal}
                        handleRemoveAttachment={handleRemoveAttachment}
                        attachmentInputRef={attachmentInputRef}
                    ></ImageUpload>
                    {/*<Box*/}
                    {/*    sx={{*/}
                    {/*        backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,*/}
                    {/*        padding: 2,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Typography variant="subtitle1">INCLUDE IMAGE</Typography>*/}
                    {/*    <input*/}
                    {/*        ref={attachmentInputRef}*/}
                    {/*        style={{ marginTop: 10, cursor: 'pointer' }}*/}
                    {/*        type="file"*/}
                    {/*        onChange={onChangeAttachment}*/}
                    {/*    />*/}
                    {/*    <Button onClick={handleOpenImageModal} variant="contained" style={{ marginTop: 10 }}>*/}
                    {/*        Select existing image*/}
                    {/*    </Button>*/}
                    {/*    {attachmentUrl && (*/}
                    {/*        <>*/}
                    {/*            <img*/}
                    {/*                style={{ maxWidth: '400px', maxHeight: '400px', objectFit: 'contain', marginTop: 10 }}*/}
                    {/*                src={attachmentUrl}*/}
                    {/*                alt="File"*/}
                    {/*            />*/}
                    {/*            <Button style={{ marginTop: 10 }} onClick={handleRemoveAttachment}>*/}
                    {/*                Remove*/}
                    {/*            </Button>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</Box>*/}

                    <CampaignTemplates
                        handleSaveMessage={handleSaveMessage}
                        message={message}
                        setMessage={setMessage}
                        campaign={campaign}
                        selectedTemplates={selectedTemplates}
                        setSelectedTemplates={setSelectedTemplates}
                        updateTemplates={updateTemplates}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                    ></CampaignTemplates>

                    <CampaignStaff campaign={campaign}></CampaignStaff>
                </Grid>
            </Grid>

            <Modal open={isImageModalOpen} onClose={handleCloseImageModal}>
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '80vh',
                        overflowY: 'auto',
                    }}
                >
                    <Typography
                        variant="h6"
                        component="h2"
                        sx={{
                            marginBottom: 2,
                            color: theme === 'dark' ? Colors.MAIN_WHITE : Colors.MAIN_DARK,
                        }}
                    >
                        Select an Image
                    </Typography>

                    <Grid container spacing={2} style={{ marginTop: 2 }}>
                        {localAttachments.map((file, index) => (
                            <Grid item xs={12} sm={6} md={4} key={file.id}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        '&:hover .delete-icon': {
                                            display: 'block',
                                        },
                                    }}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${file.server_name}`}
                                        alt={`Image ${index}`}
                                        style={{ width: '100%', height: 'auto', borderRadius: 5, }}
                                        onClick={() => handleSelectImage(file.id)}
                                    />
                                    <IconButton
                                        className="delete-icon"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent triggering the image selection
                                            handleDeleteImage(file.id);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            color: 'red',
                                            display: 'none',
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Modal>

            <ModalSendCampaign
                open={isSendCampaignModal}
                close={() => setSetCampaignModal(false)}
                campaign_id={campaign.id}
                templatesLength={campaign.templates.length}
                message={message}
            ></ModalSendCampaign>
            <ModalLoadContacts
                filter_master={filterMaster}
                filter_channels={filterChannels}
                lists={selectedLists}
                campaign_id={campaign.id}
                file={file}
                close={() => setFile(null)}
            ></ModalLoadContacts>
        </>
    );
};
