import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Modal, Select, Stack, Switch, TextField, Typography, OutlinedInput } from "@mui/material"
import * as XLSX from "xlsx";
import { Colors } from "../../../../types"
import { useTheme } from '../../../../services/theme'
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getLists } from '../../../../redux/slices/list';
import {deleteCampaignLists, getCampaignLists} from "../../../../redux/campaign/campaign.request";
import {useAuth} from "../../../../services/authContext";
import { v4 as uuidv4 } from 'uuid';

type Props = {
    filterMaster: boolean
    setFilterMaster: (v: boolean) => void
    filterChannels: boolean
    setFilterChannels: (v: boolean) => void
    setFile: (v: any) => void
    selectedLists: any[]
    setSelectedLists: (v: any) => void
    onFilterMaster: (v: boolean) => void
    onFilterChannels: (v: boolean) => void
    onSelectedChannels: (v: any) => void
}

export const CampaignContacts = ({ filterChannels, filterMaster, selectedLists, setSelectedLists,
                                     setFile, setFilterChannels, setFilterMaster, onFilterMaster, onFilterChannels, onSelectedChannels }: Props) => {
    const { lists } = useSelector(store => store.list)
    const [isRemoveList, setIsRemoveList] = useState(false)

    const hiddenInput = useRef<any>(null);
    const theme = useTheme()
    const dispatch = useDispatch()
    const { me } = useAuth()

    const handleClickUpload = (event: any) => {
        hiddenInput.current.click();
    };

    useEffect(() => {
        dispatch(getLists())
    }, [])

    const handleFileUpload = (e: any) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e: any) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData: any[] = XLSX.utils.sheet_to_json(sheet, { defval: '' });
          if (hiddenInput.current) {
            hiddenInput.current.value = "";
            hiddenInput.current.type = "text";
            hiddenInput.current.type = "file";
          }

          if(parsedData.length) {
            setFile(parsedData as any)
          }
        };
    };

    return (
        <Box
            sx={{ 
                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                // height: 'calc(100% - 30px)',
                padding: 2,
                marginBottom: 1,
            }}
        >
            <Typography variant="subtitle1">CAMPAIGN OPTIONS & UPLOAD CONTACTS</Typography>
            <Typography variant="caption">Use the options to choose additional settings for your campaign before you upload.</Typography>
            
            <Stack marginTop={3} display={'flex'} justifyContent={'space-between'} direction={{ xs: 'column', sm: 'column', lg: 'row' }}  spacing={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Typography style={{ color: 'skyblue' }} variant="caption">Upload a list before you send your campaign</Typography>
                    <Typography style={{ color: 'orange' }} variant="caption">Download Contact Excel Template</Typography>
                    <div 
                        style={{ 
                            height: 200, width: '100%',  marginTop: 2, cursor: 'pointer', textAlign: 'center',
                            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                            backgroundColor: theme === 'dark' ? Colors.SECONDARY_DARK : Colors.SECONDARY_LIGTH, 
                        }}
                        onClick={handleClickUpload}
                    >
                        {<FontAwesomeIcon style={{ marginBottom: 10 }} size="3x" icon={"fa-solid fa-cloud-arrow-up".split(' ') as any}></FontAwesomeIcon>}
                        Upload campaign xlsx sheet
                        <input onChange={handleFileUpload} type="file" style={{ display: 'none' }} ref={hiddenInput}></input>
                    </div>
                    <Button onClick={() => setIsRemoveList(true)} style={{ marginTop: 10 }} variant='contained'>Remove List</Button>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    <Typography variant="subtitle1">Options</Typography>

                    {(me?.role === 'manager' || me?.role === 'admin') && <Box>
                        <Box
                            sx={{marginTop: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <Typography variant="subtitle2">Filter Master</Typography>
                            <Switch disabled={filterChannels} checked={filterMaster} onChange={e => onFilterMaster(e.target.checked)}></Switch>
                        </Box>
                        <Typography variant="caption">Filter out master list numbers.</Typography>
                    </Box>}

                    <Box>
                        <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle2">Filter Channels</Typography>
                            <Switch disabled={filterMaster} checked={filterChannels} onChange={e => onFilterChannels(e.target.checked)}></Switch>
                        </Box>
                        <Typography variant="caption">Filter out contacts that already have assigned channels.</Typography>
                    </Box>
                   
                    <Box>
                        <MultiSelectContactsLists
                            array={lists}
                            selected={selectedLists}
                            setSelected={setSelectedLists}
                            onSelected={onSelectedChannels}
                        ></MultiSelectContactsLists>
                    </Box>
                </Box>
            </Stack>

            {isRemoveList &&
              <Modal 
                style={{ backgroundColor: theme === 'dark' ? Colors.MAIN_LIGTH : Colors.MAIN_LIGTH }} 
                open={!!isRemoveList} 
                onClose={() => setIsRemoveList(false)}
            >
               <ModalDeleteList close={() => setIsRemoveList(false)}></ModalDeleteList>
          </Modal>}
        </Box>
    )
}

export const ModalDeleteList = ({ close }: { close: () => void }) => {
    const { campaign, lists } = useSelector(store => store.campaign)
    
    const dispatch = useDispatch()
    const theme = useTheme()

    useEffect(() => {
        if(campaign) dispatch(getCampaignLists({ id: campaign.id }))
    }, [])

    const onDelete = (id: string) => {
        dispatch(deleteCampaignLists({ id }))
    }

    return (
        <Box 
            sx={{ 
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                bgcolor: 'background.paper',
                color: 'text.primary',
                boxShadow: 24,
                p: 4, 
            }}
        >
            {
                lists.map(list => <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>{list.list_name}</Typography> <Button color='error'  onClick={() => onDelete(list.list_id)}>Delete</Button>
                </Box>)
            }
        </Box>
    )
}

export const MultiSelectContactsLists = ({ array, selected, setSelected, onSelected }: {
    array: any[], selected: any[], setSelected: any, onSelected: (data: any) => void }) => {
 
    return (
      <FormControl sx={{ marginTop: 2, width: '100%' }}>
        <InputLabel>Multiple Select</InputLabel>
        <Select
          multiple
          value={selected}
          onChange={(e: any) => {onSelected(e.target.value)}}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value: any) => (
                <Chip
                  key={uuidv4()}
                  label={<div onClick={() => onSelected(array.find(it => it.id === value))}>{array.find(it => it.id === value)?.name}</div>}
                />
              ))}
            </Stack>
          )}
        >
          {array.map((item, n) => (
            <MenuItem
              key={item.id}
              value={item.id}
              sx={{ justifyContent: "space-between" }}
            >
              {item.name}
              {selected?.includes(item.id) ? <FontAwesomeIcon color="green" icon={"fa-solid fa-check".split(' ') as any}></FontAwesomeIcon> : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};
