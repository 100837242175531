import {forwardRef, useCallback, useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, Button, Stack, Modal,TextField, Divider, InputLabel, FormControl, Select, OutlinedInput, Chip, MenuItem, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { useDispatch, useSelector } from "../../../redux/store";
import { Colors, KeyWords } from "../../../types";
import { useTheme } from "../../../services/theme";
import { ITemplate, clearTemplate, createTemplate, deleteTemplate, getTemplates, updateTemplate } from "../../../redux/slices/templates";
import { toast } from "react-toastify";
import {
    getListDetailsCampaign,
    importCampaignContacts,
    sendCampaignSingle
} from "../../../redux/campaign/campaign.request";
import {importContact} from "../../../redux/slices/contacts";
import {useAuth} from "../../../services/authContext";

export const ModalEditTemplate = forwardRef(({ close }: { close: () => void }) => {
    const [isOpenModalCreate, setIsOpenModalCreate] = useState<ITemplate | boolean>(false)
    const { templates } = useSelector(store => store.templates)
    
    const dispatch = useDispatch()
    const theme = useTheme()

    const {me} = useAuth()

    useEffect(() => {
        if(me)
            dispatch(getTemplates({user_id: me?.id}))
    }, [me])

    const onDelete = (id: string) => {
        dispatch(deleteTemplate(id))
    }

    const onEdit = (template: ITemplate) => {
        setIsOpenModalCreate(template)
    }

    return (
        <Box 
            sx={{ 
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto',
                minWidth: '50%',
                maxHeight: '50%',
                bgcolor: 'background.paper',
                color: 'text.primary',
                boxShadow: 24,
                p: 4, 
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', /*alignItems: 'center'*/ }}>
                <Typography>Choose a Template or <Button onClick={() => setIsOpenModalCreate(true)}>Create message templates</Button></Typography>
                <Box style={{ cursor: 'pointer' }} onClick={close}>
                    {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                </Box>
            </Box>

            <Box sx={{ marginTop: 2, overflowY: 'auto', maxHeight: '300px'}}>
                {
                    templates.map(template => 
                        <Box key={template.id} sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2, marginRight: 1 }}>
                            <Box sx={{ maxWidth: '200px' }}>
                                { template.name }
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                {template.text.length > 70 ? template.text.slice(0, 70) + '...' : template.text}
                            </Box>
                            <Box>
                                {<FontAwesomeIcon onClick={() => onEdit(template)} style={{ cursor: 'pointer' }} color="green" icon={"fa-solid fa-pen-to-square".split(' ') as any}></FontAwesomeIcon>}
                                {<FontAwesomeIcon onClick={() => onDelete(template.id)} style={{ cursor: 'pointer', marginTop: 10 }} color="red" icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                            </Box>
                        </Box>
                    )
                }
            </Box>

            <Modal 
                style={{ backgroundColor: theme === 'dark' ? Colors.MAIN_LIGTH : Colors.MAIN_LIGTH }} 
                open={!!isOpenModalCreate} 
                onClose={() => setIsOpenModalCreate(false)}
            >
                <ModalCreateTemplate template={isOpenModalCreate} close={() => setIsOpenModalCreate(false)}></ModalCreateTemplate>
            </Modal>
        </Box>
    )
})


const ModalCreateTemplate = ({ template, close }: { template: ITemplate | boolean, close: () => void }) => {
    const [name, setName] = useState('')
    const [text, setText] = useState('')
    const { me } = useAuth()

    const dispatch = useDispatch()

    const onSubmit = () => {
        if(!name || !text) { console.log('err'); return 'err' }
        
        if (template && typeof template !== 'boolean') {
            dispatch(updateTemplate({ id: template.id, payload: { text, name } }))
        } else {
            dispatch(createTemplate({ name, text, user_id: me?.id }))
        }
        close()
    }

    useEffect(() => {
        if (template && typeof template !== 'boolean') {
            setName(template.name)
            setText(template.text)
        }

        return () => { dispatch(clearTemplate()) }
    }, [])

    const onAddCustomText = (text: string) => {
        setText(prev => prev + ' ' + text)
    }

    return (
        <Box 
            sx={{ 
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                bgcolor: 'background.paper',
                color: 'text.primary',
                boxShadow: 24,
                p: 4, 
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', /*alignItems: 'center'*/ }}>
                <Typography variant="h6">{template && typeof template !== 'boolean' ? 'Update' : 'Create'} message template</Typography>
                <Box style={{ cursor: 'pointer' }} onClick={close}>
                    {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                </Box>
            </Box>
            <Divider style={{ marginTop: 10 }}></Divider>

            <TextField
                value={name}
                onChange={e => setName(e.target.value)}
                style={{ marginTop: 20 }} 
                fullWidth 
                placeholder="Template Name"
            />

            <TextField
                value={text}
                onChange={(e) => setText(e.target.value)}
                style={{ marginTop: 20 }}
                fullWidth
                placeholder="Message Template"
                multiline
                rows={4}
            />

            <Button onClick={() => onAddCustomText(`{@Pick( | | )}`,)} style={{ marginTop: 10, marginRight: 6 }} variant="contained">Pick</Button>
            {(Object.keys(KeyWords) as Array<keyof typeof KeyWords>).map(it => 
                <Button onClick={() => onAddCustomText(`{${KeyWords[it]}}`,)} style={{ marginTop: 10, marginRight: 6 }} variant="contained">{KeyWords[it]}</Button>
            )}

            <Divider style={{ marginTop: 40 }}></Divider>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">{template && typeof template !== 'boolean' ? 'Update' : 'Create'}</Button>
            </Box>
        </Box>
    )
}

type ModalSendCampaignProps = {
    open: boolean, 
    close: () => void,
    campaign_id: string,
    templatesLength: number
    message: string
}

export const ModalSendCampaign = ({ open, close, campaign_id, templatesLength, message }: ModalSendCampaignProps) => {

    const dispatch = useDispatch()

    const onSubmit = async () => {
        if(!message && !templatesLength) {
            toast('you should select sms template or write message', { type: 'error' })
            return
        }
        close()
        await dispatch(sendCampaignSingle({ id: campaign_id, payload: { message } }))
    }

    return (
        <Modal 
            open={open} 
            onClose={close}
        >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography>Send Campaign?</Typography>
                    {/*<Typography variant="subtitle2" style={{ marginTop: 10 }}>{message}</Typography>*/}
                    <Box sx={{ display: 'flex', gap: 2, marginTop: 4 }}>
                        <Button onClick={close} color="error" variant="contained">Cancel</Button>
                        <Button onClick={onSubmit} variant="contained">Confirm</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export const MultiSelectCampaignTemplates = ({ campaignTemplates, updateTemplates,  array, selected, setSelected, setSelectedTemplate }:
                                             { array: any[], updateTemplates: any, selected: any[], setSelected: any, setSelectedTemplate: any, campaignTemplates: any}) => {
    return (
      <FormControl sx={{ marginTop: 2, width: '100%' }}>
        <InputLabel>Multiple Select</InputLabel>
        <Select
          multiple
          value={selected}
          onChange={(e: any) => updateTemplates(e.target.value)}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value: any) => (
                <Chip
                  key={value}
                  label={<div onClick={() => setSelectedTemplate(array.find(it => it.id === value))}>{array.find(it => it.id === value)?.name}</div>}
                  onDelete={
                    () => setSelectedTemplate(campaignTemplates.find((template: any) => template.id === value))
                  }
                  deleteIcon={
                    <div onMouseDown={(event) => event.stopPropagation()}>{<FontAwesomeIcon size='xs' color="orange" icon={"fa-solid fa-edit".split(' ') as any}></FontAwesomeIcon>}</div>
                  }
                />

                // <div
                //     key={value}
                //     style={{ zIndex: 9999, display: 'flex', alignItems: 'center', gap: 6, backgroundColor: Colors.SECONDARY_DARK, padding: '8px 14px', borderRadius: 18 }}
                // >
                //     <span onClick={() => setSelectedTemplate(campaignTemplates.find((template: any) => template.id === value))}>{array.find(it => it.id === value)?.name}</span>
                //     <FontAwesomeIcon 
                //         onClick={() => setSelected(selected.filter((item) => item !== value))} 
                //         color="red" icon={"fa-solid fa-x".split(' ') as any}
                //     ></FontAwesomeIcon>
                // </div>
              ))}
            </Stack>
          )}
        >
          {array.map((item, n) => (
            <MenuItem
              key={item.id}
              value={item.id}
              sx={{ justifyContent: "space-between" }}
            >
              {item.name}
              {selected.includes(item.id) ? <FontAwesomeIcon color="green" icon={"fa-solid fa-check".split(' ') as any}></FontAwesomeIcon> : null}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};

type Props = {
    file: any
    close: () => void
    campaign_id: string
    filter_master: boolean
    filter_channels: boolean
    lists: any[]
}

export const ModalLoadContacts = ({ file, close, campaign_id, filter_master ,filter_channels, lists }: Props) => {
    const [firstKeysWithoutPrep, setFirstKeysWithoutPrep] = useState<string[] | null>(null)
    const [firstKeys, setFirstKeys] = useState<string[] | null>(null)
    const [keys, setKeys] = useState<string[] | null>(null)
    const [listName, setListName] = useState('')
    const [disabledButton, setDisabledButton] = useState(false);
    const dispatch = useDispatch()
    
    useEffect(() => {
        if(file) {
            setKeys(Object.keys(file[0]).map(it => it.toLocaleLowerCase().replace(' ', '_').replace(/[.]/, '')))
            setFirstKeys(Object.keys(file[0]).map(it => it.toLocaleLowerCase().replace(' ', '_').replace(/[.]/, '')))
            setFirstKeysWithoutPrep(Object.keys(file[0]))
        }
    }, [file])

    useEffect(() => {
        if(file) {
            const numColumns = Object.keys(file[0]).length;
            setKeys(Array(numColumns).fill('not_include'));
            setFirstKeys(Object.keys(file[0]).map(it => it.toLocaleLowerCase().replace(' ', '_').replace(/[.]/, '')));
            setFirstKeysWithoutPrep(Object.keys(file[0]));
        }
    }, [file]);

    const onSubmit = async () => {
        if(!listName) {
            toast('You should write list name')
            return 
        }
        if(!keys?.includes('number')) {
            toast('You should select number field')
            return 
        }
        const duplicatesFound = duplicates(keys);
        if(duplicatesFound.length) {
            // console.log("Duplicate elements: " + duplicatesFound.join(" "));
            return
        }

        const preparedFile: any[] = file.map((it: any, n: number) => {
            const obj: any = {}
            if(firstKeysWithoutPrep) keys.map((key, n) => { 
                if(key === 'not_include') return
                obj[key] = it[firstKeysWithoutPrep[n]]
            })
            return obj
        })

        for(let row of preparedFile) {
            if(!row.first_name) row.first_name = row.number
        }

        setDisabledButton(true);

        try {
            const res = await dispatch(importCampaignContacts({
                id: campaign_id, payload: { contacts: preparedFile, filter_master, filter_channels, list_name: listName, lists }
            }))

            if (res.payload) {
                close();
                await dispatch(getListDetailsCampaign({id: campaign_id}));
            }
        } catch (error) {
            console.error("Error uploading contacts:", error);
        } finally {
            setDisabledButton(false);
        }

    }



    if(!file?.length) return null

    return (
        <Modal 
            open={!!file} 
            onClose={close}
        >
            <Box 
                sx={{ 
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '50%',
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <TextField
                    value={listName}
                    onChange={(e) => setListName(e.target.value)}
                    style={{ marginTop: 30, marginBottom: 30 }} 
                    placeholder="List Name"
                    fullWidth
                ></TextField>
                <Typography>Select number, first_name and last_name fields</Typography>
                <TableContainer style={{ maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        {
                            keys?.map((it, n) => {

                                return <TableCell key={it + n}>
                                <FormControl style={{ marginTop: 10, width: '100%', }}>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={it}
                                        onChange={(e => {
                                            setKeys(prev => prev?.map((item, j) => n !== j ? item : e.target.value ) || null )
                                        })}
                                    >
                                        {firstKeys && <MenuItem value={firstKeys[n]}>{firstKeys[n]}</MenuItem>}
                                        {firstKeys && firstKeys[n] !== 'number' && <MenuItem value={'number'}>number</MenuItem>}
                                        {firstKeys && firstKeys[n] !== 'first_name' && <MenuItem value={'first_name'}>first_name</MenuItem>}
                                        {firstKeys && firstKeys[n] !== 'last_name' && <MenuItem value={'last_name'}>last_name</MenuItem>}
                                        <MenuItem value={'not_include'}>not_include</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            })
                        }
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...file]?.slice(0, 4).map((file: any, n) => {
                            const keys = Object.keys(file)

                            return <TableRow
                                key={n}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                
                                {
                                    keys.map((it, n) => 
                                        <TableCell key={it + n} style={{ cursor: 'pointer' }} component="th" scope="row">{file[it]} </TableCell>
                                    )
                                }
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onSubmit} disabled={disabledButton} size="large" style={{ marginTop: 20 }} variant="contained">Upload</Button>
            </Box>
            </Box>
        </Modal>
    )
}

function duplicates(arr: string[]) {
    const freqMap: any = {};
    const result = [];

    for (let num of arr) {
        freqMap[num] = (freqMap[num] || 0) + 1;
    }

    for (let key in freqMap) {
        if (freqMap[key] > 1 && key !== 'not_include') {
            result.push(String(key));
        }
    }

    return result;
}
