import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faImages, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface ImageUploadProps {
    theme: 'dark' | 'light';
    attachmentUrl: string | null;
    onChangeAttachment: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleOpenImageModal: () => void;
    handleRemoveAttachment: () => void;
    attachmentInputRef: React.RefObject<HTMLInputElement>;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
                                                            theme,
                                                            attachmentUrl,
                                                            onChangeAttachment,
                                                            handleOpenImageModal,
                                                            handleRemoveAttachment,
                                                            attachmentInputRef,
                                                        }) => {
    return (
        <Box
            sx={{
                backgroundColor: theme === 'dark' ? '#333' : '#fff',
                padding: 3,
                borderRadius: 2,
                boxShadow: theme === 'dark' ? '0 0 10px #222' : '0 0 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                margin: 'auto',
            }}
        >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Include Image
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <Button
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faUpload} />}
                    component="label"
                    sx={{ margin: 2 }}
                >
                    Upload
                    <input
                        ref={attachmentInputRef}
                        type="file"
                        hidden
                        onChange={onChangeAttachment}
                    />
                </Button>


                <Button
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faImages} />}
                    onClick={handleOpenImageModal}
                    sx={{ margin: 2 }}
                >
                    Select existing image
                </Button>
            </Box>


            {attachmentUrl && (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            style={{
                                maxHeight: 300,
                                objectFit: 'contain',
                                marginTop: 10,
                                borderRadius: 8,
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                            }}
                            src={attachmentUrl}
                            alt="File"
                        />
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',

                        }}
                    >
                        <Button
                            variant="text"
                            color="error"
                            startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
                            onClick={handleRemoveAttachment}
                            sx={{ marginTop: 1 }}
                        >
                            Remove
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
};