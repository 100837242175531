import { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs, { Dayjs } from 'dayjs';
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, Input, InputLabel, TextField, Divider, Select, FormControl, MenuItem, ButtonGroup, OutlinedInput, SelectChangeEvent, Grid } from "@mui/material"
import { Colors } from "../../../types";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import moment from "moment";
import { useDispatch, useSelector } from "../../../redux/store";
import { autocompleteSms, clearAutocompleteSms, getSmsList, loadMoreSms } from "../../../redux/slices/sms";
import useDebounce from "../../../services/hooks";
import ExcelJS from 'exceljs'
import { Request } from "../../../redux/request";
import { useAuth } from "../../../services/authContext";
import { getUsers } from "../../../redux/user/user.request";
import {getAllCampaigns} from "../../../redux/campaign/campaign.request";

export const Reports = () => {
    const { me } = useAuth()
    const { sms_list, autocomplete } = useSelector(store => store.sms)
    const { select } = useSelector(store => store.campaign)
    const {users} = useSelector(store => store.user); 
    const [openModal, setOpenModal] = useState(false);
    const [direction, setDirection] = useState('all')
    const [date, setDate] = useState<DateRange<Dayjs>>([
        dayjs(moment().format('YYYY-MM-DD')),
        dayjs(moment().add(1, 'days').format('YYYY-MM-DD')),
    ]);
    const [text, setText] = useState('')
    const [showAll, setShowAll] = useState(false)
    const debouncedText = useDebounce(text, 200);
    const [campaignId, setCampaignId] = useState('none')
    const [userID, setUserID] = useState('none')
    const [horizontalScroll, setHorizontalScroll] = useState(0)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllCampaigns())
        dispatch(getUsers())
    }, [])
    // console.log(userID);
    // console.log(campaignId); 
    useEffect(() => {
        dispatch(getSmsList({ text, start: date[0], end: date[1], direction, show_all: showAll, campaign_id: campaignId, userId: userID }))
    }, [ debouncedText ,date, direction, showAll, campaignId, userID])

    // useEffect(() => {
    //     if (text) dispatch(autocompleteSms(text));

    //     return () => {
    //     dispatch(clearAutocompleteSms());
    //     };
    // }, [debouncedText]);

    const sms = useMemo(() => autocomplete || sms_list, [autocomplete, sms_list])

    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 0.5;
        
        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft)
            return
        }

        if (isAtBottom) {
            dispatch(loadMoreSms({ text, start: date[0], end: date[1], direction, show_all: showAll, campaign_id: campaignId, userId: userID}))
        }
    }, [horizontalScroll, text, date, direction, campaignId, showAll])

    const downloadData = async () => {
        const res = await Request.get(`sms/download?text=${text}&date_start=${date[0]}&date_end=${date[1]}&direction=${direction}&campaign=${campaignId}&all=${showAll}&userId=${userID}`);
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("My Sheet");
    
        sheet.columns = [
          { header: "id", key: "id", width: 45,},
          { header: "number", key: "number", width: 15,},
          { header: "name", key: "name", width: 25,},
          { header: "text", key: "text", width: 40 },
          { header: "direction", key: "direction", width: 10,},
          { header: "time", key: "time", width: 20,},
        ];
    
        res.sms_list?.map((it: any) => {
          sheet.addRow({
            id: it?.id,
            number: it?.chat_number,
            name: it?.contact_name,
            text: it?.text,
            direction: it?.direction,
            time: moment(it?.createdAt).format('MM/DD/YYYY h:mm A'),
          });
        })
    
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "download.xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }    
   
    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">Reports</Typography>
                <TextField value={text} onChange={e => setText(e.target.value)} style={{ width: '50%', height: '100%' }} placeholder="Search"></TextField>
            </Box>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4, width: '100%' }}>
                <Grid container spacing={1}>
                    <Grid item xs={10} sm={4} md={3}>
                        <Button onClick={downloadData} style={{ width: '100%', height: '100%' }} variant="contained">Download Messages List</Button>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                        {me?.role === 'manager' && <Button style={{ height: '100%' }} onClick={() => setShowAll(prev => !prev)} size="small" variant="contained">
                            <FontAwesomeIcon color={showAll ? 'yellow' : 'white'} icon={"fa-solid fa-eye".split(' ') as any}></FontAwesomeIcon>
                        </Button>}
                    </Grid>
                    <Grid item xs={12} sm={6} md={7}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer sx={{ width: '100%' }} components={['DateRangePicker']}>
                                <DateRangePicker value={date} onChange={(newValue) => setDate(newValue)} localeText={{ start: 'date-start', end: 'date-end' }} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Container>
            
            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 320px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>FROM</TableCell>
                        <TableCell>TO</TableCell>
                        <TableCell>MESSAGE</TableCell>
                        <TableCell style={{ minWidth: 100 }}>
                            <FormControl style={{ marginTop: 10, width: '100%', }}>
                                <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">DIRECTION</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="DIRECTION"
                                    value={direction}
                                    onChange={e => setDirection(e.target.value)}
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    <MenuItem value={'all'}>All</MenuItem>
                                    <MenuItem value={'incoming'}>INCOMING</MenuItem>
                                    <MenuItem value={'outgoing'}>OUTGOING</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell><FormControl style={{ marginTop: 10, width: '100%', }}>
                                <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Select USER</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select USER"
                                    value={userID}
                                    onChange={e => setUserID(e.target.value as string)}
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    {
                                        users.map(it => <MenuItem key={it.id} value={it.id}>{it.firstname} {it.lastname}</MenuItem>)
                                    }
                                </Select>
                            </FormControl></TableCell>
                        <TableCell>DATE</TableCell>
                        <TableCell style={{ minWidth: 150 }}>
                            <FormControl style={{ marginTop: 10, width: '100%', }}>
                                <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Select Campaign</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Campaign"
                                    value={campaignId}
                                    onChange={e => setCampaignId(e.target.value as string)}
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    <MenuItem value={'none'}>Without</MenuItem>
                                    {
                                        select.map(it => <MenuItem key={it.id} value={it.id}>{it.name}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {sms.map((sms) => (
                        <TableRow
                            key={sms.id + Math.random()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>{sms.direction === 'incoming' ? sms.chat?.number : sms.chat?.sending_number}</TableCell>
                            <TableCell>{sms.direction === 'incoming' ? sms.chat?.sending_number : sms.chat?.number}</TableCell>
                            <TableCell component="th" scope="row">{sms.text} </TableCell>
                            <TableCell>{sms.direction}</TableCell>
                            <TableCell>{sms.user?.firstname} {sms.user?.lastname}</TableCell>
                            <TableCell>{moment(sms.createdAt).format('MM/DD/YYYY')}</TableCell>
                            <TableCell>{sms.campaign?.name || 'none'}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box 
                    sx={{ 
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        boxShadow: 24,
                        p: 4, 
                    }}
                >
                    
                </Box>
            </Modal>
        </div>
    )
}
