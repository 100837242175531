import React, { useEffect, useState } from "react";
import {
    Box, Button,
    FormControl, Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import {useDispatch, useSelector} from "../../../redux/store";
import {getDashboard, reloadStatsToDay} from "../../../redux/dashboard/dashboard.request";
import {statusReload} from "../../../redux/dashboard/dashboard.slice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {IGroupedCardStats, IGroupedTableStats} from "../../../redux/dashboard/dashboard.interface";
import {CardInfoDashboard, CardListInfoDashboard} from "./utils";
import {toast} from "react-toastify";
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
type Mode = 'day' | 'week' | 'month';

const DashboardManager: React.FC = () => {

    const dispatch = useDispatch();

    const { card, table } = useSelector(store => store.dashboard);


    const [mode, setMode] = useState<Mode>('day');
    const [date, setDate] = useState<string>('');

    const handleModeChange = (event: SelectChangeEvent) => {
        const newMode = event.target.value as Mode;
        setMode(newMode);
        setDate('');
        console.log('handleModeChange', newMode);
    };

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
        console.log('handleDateChange', event.target.value);
    };

    const onFilter = () => {

        if (date === '') {
            toast('Please select date', {type: 'error'})
            return;
        }

        let startDate: Dayjs;
        let endDate: Dayjs;

        switch (mode) {
            case 'day':
                startDate = dayjs(date);
                endDate = startDate.endOf('day');
                break;

            case 'week':
                const [year, week] = date.split('-W').map(Number);
                if (!year || !week || week < 1 || week > 53) {
                    toast('Invalid week format. Use YYYY-WWW', { type: 'error' });
                    return;
                }

                startDate = dayjs().year(year).isoWeek(week).startOf('isoWeek');
                endDate = dayjs().year(year).isoWeek(week).endOf('isoWeek');
                break;

            case 'month':
                startDate = dayjs(date).startOf('month');
                endDate = dayjs(date).endOf('month');
                break;

            default:
                throw new Error('Invalid mode');

        }

        const startDateString = startDate.toISOString();
        const endDateString = endDate.toISOString();

        dispatch(getDashboard({startDate: startDateString, endDate: endDateString}))

    }

    let dateType: 'date' | 'week' | 'month' = 'date';
    if (mode === 'week') {
        dateType = 'week';
    } else if (mode === 'month') {
        dateType = 'month';
    }

    return (
        <Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px'
                }}
            >

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>

                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <FormControl size="small">
                        <InputLabel id="mode-select-label">Mode</InputLabel>
                        <Select
                            labelId="mode-select-label"
                            id="mode-select"
                            value={mode}
                            label="Режим"
                            onChange={handleModeChange}
                        >
                            <MenuItem value="day">Day</MenuItem>
                            <MenuItem value="week">Week</MenuItem>
                            <MenuItem value="month">Month</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        size="small"
                        type={dateType}
                        label={
                            mode === 'day' ? 'Day' :
                                mode === 'week' ? 'Week' :
                                    'Month'
                        }
                        InputLabelProps={{ shrink: true }}
                        value={date}
                        onChange={handleDateChange}
                    />

                    <Button onClick={onFilter}>Filter</Button>
                </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row" }}>
                {card.map((stat: IGroupedCardStats, index) => (
                    <Box
                        key={index}
                        sx={{
                            flex: 1,
                            marginRight: index !== card.length - 1 ? 2 : 0,
                        }}
                    >
                        <CardInfoDashboard
                            title={stat.title || ''}
                            value={stat.value || 0}
                            icon={<FontAwesomeIcon icon={`fa-solid ${stat.icon}`.split(" ") as any}></FontAwesomeIcon>}
                        />
                    </Box>
                ))}
            </Box>

            <Grid sx={{ marginTop: '20px' }} container spacing={2}>
                {table.map((cardData: IGroupedTableStats, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardListInfoDashboard
                            title={cardData.title}
                            columns={cardData.columns}
                            tableIndex={cardData.tableIndex}
                            data={cardData.data}
                        />
                    </Grid>
                ))}
            </Grid>

        </Box>
    );
};

export default DashboardManager;
