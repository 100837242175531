import React, {useCallback} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Table, TableBody, TableCell,
    TableContainer, TableFooter, TableHead, TablePagination, TableRow,
    Typography
} from "@mui/material";
import {IAnalyzeListSim, IDevices} from "../../../redux/analyze/analyze.interface";
import Countdown from "./components/Countdown";

interface SimStatTableProps {
    data: IAnalyzeListSim[];
    columns: string[];
    offset: number;
    limit: number;
    totalCount: number;
    onPageChange: (newOffset: number, newLimit: number) => void;
    handleDeviceChange: (event: SelectChangeEvent<string>) => void;
    devices: IDevices[];
    selectedDevice: string;
    handleButtonClick: () => void;
}

function DateDots({ value }: { value: string }) {
    const [dot1, dot2, dot3] = value.split('-');
    return (
        <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'center' }}>
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: dot1,
                }}
            />
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: dot2,
                }}
            />
            <Box
                sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: dot3,
                }}
            />
        </Box>
    );
}


function highlightText(text: string) {

    const lower = text.toLowerCase();
    let color = '';

    if (lower.includes('flagged')) {
        color = '#FB8C00';
    } else if (lower.includes('clean') || lower.includes('active')) {
        color = '#00ACC1';
    }

    return (
        <Box
            component="span"
            sx={{
                backgroundColor: '#f0f0f0',
                px: 0.5,
                borderRadius: 1,
                p: '3px',
                fontWeight: 'bold',
                color: color || 'inherit',
            }}
        >
            {text}
        </Box>
    );
}


export const SimStatTable: React.FC<SimStatTableProps> = ({
                                                              data, columns, offset, limit,
                                                              totalCount, onPageChange, devices,
                                                              handleDeviceChange, selectedDevice,
                                                              handleButtonClick
                                                          }) => {
    const reversedColumns = [...columns].reverse();

    const handleChangePage = useCallback(
        (event: unknown, newPage: number) => {
            onPageChange(newPage * limit, limit);
        },
        [limit, onPageChange]
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newLimit = parseInt(event.target.value, 10);
            onPageChange(0, newLimit);
        },
        [onPageChange]
    );

    return (
        <>
            <Box sx={{ display: 'flex'}}>
                <FormControl size="small" sx={{ width: 200 }}>
                    <InputLabel id="device-select-label">Dev server</InputLabel>
                    <Select
                        labelId="device-select-label"
                        id="device-select"
                        value={selectedDevice}
                        label="Select dev"
                        onChange={handleDeviceChange}
                    >
                        <MenuItem value="">
                            <em>All dev</em>
                        </MenuItem>
                        {devices.map((device) => (
                            <MenuItem key={device.id} value={device.id}>
                                <Typography>{device.name}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    size="small"
                    sx={{ marginLeft: 1 }}
                    onClick={(e) => handleButtonClick()}
                >
                    Throw off \ Update
                </Button>

            </Box>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Block Status</TableCell>
                            <TableCell>Lock Status</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Device.Port.Slot</TableCell>
                            {reversedColumns.map((date) => (
                                <TableCell key={date} align="center">
                                    {date}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{highlightText(row.BlockStatus)}</TableCell>
                                <TableCell>
                                    {highlightText(row.LockStatus)}
                                    {row.LockStatus === "Lock" && row.BlockEnd && (
                                        <>
                                            &nbsp;–&nbsp;
                                            <Countdown targetDate={new Date(row.BlockEnd)} />
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>{row.PhoneNumber}</TableCell>
                                <TableCell>{row.DevicePortSlot}</TableCell>

                                {reversedColumns.map((colDate) => (
                                    <TableCell key={colDate} align="center">
                                        {row.dates[colDate]
                                            ? <DateDots value={row.dates[colDate]} />
                                            : '-'
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={totalCount}
                                rowsPerPage={limit}
                                page={Math.floor(offset / limit)}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                            />
                        </TableRow>
                    </TableFooter>

                </Table>
            </TableContainer>
        </>
    );
};