import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import {createKeyword, deleteKeyword, getKeyword, getKeywords, updateKeyword} from "./keywords.request";
import {IKeyword} from "./keywords.interface";

export type KeywordsState = {
    keyword: IKeyword | null;
    keywords: IKeyword[];
    limit: number;
    offset: number;
    isLoading: boolean;
};

const initialState: KeywordsState = {
    keyword: null,
    keywords: [],
    limit: 8,
    offset: 0,
    isLoading: false,
};



const keywordSlice = createSlice({
    name: "keyword",
    initialState,
    reducers: {
        clearKeywords: (state) => {
            state.keywords = []
            state.offset = 0
        },
    },
    extraReducers: (builder) => {
        builder
            // get
            .addMatcher(
                (action) => action.type === getKeyword.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getKeyword.fulfilled.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === getKeyword.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // get all
            .addMatcher(
                (action) => action.type === getKeywords.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === getKeywords.fulfilled.type,
                (state, action: PayloadAction<{ keywords: IKeyword[] }>) => {
                    console.log(action.payload.keywords.length)
                    if (action.payload.keywords.length > 0) {
                        state.keywords = [...state.keywords, ...action.payload.keywords];
                        state.offset += action.payload.keywords.length;
                    }

                    state.isLoading = false;
                }
            )

            .addMatcher(
                (action) => action.type === getKeywords.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // create
            .addMatcher(
                (action) => action.type === createKeyword.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === createKeyword.fulfilled.type,
                (state, action: PayloadAction<{keyword: IKeyword}>) => {
                    state.keywords = [...current(state.keywords), action.payload.keyword]
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === createKeyword.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // update
            .addMatcher(
                (action) => action.type === updateKeyword.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === updateKeyword.fulfilled.type,
                (state, action: PayloadAction<{keyword: IKeyword}>) => {
                    state.keywords = current(state).keywords.map(keyword => keyword.id === action.payload.keyword.id ? action.payload.keyword : keyword)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === updateKeyword.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
            // delete
            .addMatcher(
                (action) => action.type === deleteKeyword.pending.type,
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action) => action.type === deleteKeyword.fulfilled.type,
                (state, action: PayloadAction<{id: string}>) => {
                    state.keywords = current(state).keywords.filter(keyword => keyword.id !== action.payload.id)
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type === deleteKeyword.rejected.type,
                (state, action: any) => {
                    state.isLoading = false;
                }
            )
    },
});

export const { clearKeywords } = keywordSlice.actions;

export default keywordSlice.reducer;
