import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import campaign from "./campaign/campaign.slice";
import user from "./user/user.slice";
import templates from "./slices/templates";
import keywords from "./keywords/keywords.slice";
import contacts from "./slices/contacts";
import chat from "./chat/chat.slice";
import sms from "./slices/sms";
import blocked from "./blocked/blocked.slice";
import server from "./server/server.slice";
import groups from "./slices/groups";
import responses from "./slices/responses";
import list from "./slices/list";
import dashboard from "./dashboard/dashboard.slice";
import analyze from "./analyze/analyze.slice";

export const store = configureStore({
    reducer: {
      user,
      campaign,
      templates,
      keywords,
      contacts,
      chat,
      sms,
      blocked,
      server,
      groups,
      responses,
      list,
      dashboard,
      analyze
    },
  });

export type RootStateType = ReturnType<typeof store.getState>;
export type AppDispatchType = typeof store.dispatch;
export const useAppDispatch = () => useReduxDispatch<AppDispatchType>();

export const useSelector: TypedUseSelectorHook<RootStateType> = useReduxSelector
export const useDispatch = useReduxDispatch<AppDispatchType>
